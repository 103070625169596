<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <form
      class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
    >
      <!-- <div class="form-group mb-0">
                <base-input placeholder="Search"
                            class="input-group-alternative"
                            alternative=""
                            addon-right-icon="fas fa-search">
                </base-input>
            </div> -->
    </form>
    <ul class="navbar-nav align-items-center d-none d-md-flex mr-5">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <div class="media align-items-center" slot="title">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="../assets/admin.png" />
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span
                class="mb-0 text-sm font-weight-bold"
                style="pointer-events: none"
                >{{ user.firstName }} {{ user.lastName }}</span
              >
            </div>
          </div>

          <template>
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Bienvenu!</h6>
            </div>
            <router-link
              to="/profil"
              class="dropdown-item"
              v-if="user.role == 'admin'"
            >
              <i class="ni ni-single-02"></i>
              <span>Profile</span>
            </router-link>
            <!-- <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-settings-gear-65"></i>
                            <span>Settings</span>
                        </router-link>
                        <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-calendar-grid-58"></i>
                            <span>Activity</span>
                        </router-link>
                        <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-support-16"></i>
                            <span>Support</span>
                        </router-link> -->
            <div class="dropdown-divider"></div>
            <div @click="logout">
              <router-link to="#" class="dropdown-item">
                <i class="ni ni-user-run"></i>
                <span>Déconnexion</span>
              </router-link>
            </div>
          </template>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
    };
  },
  computed: {
    user() {
      return this.$store.state.admin.user;
    },
  },
  methods: {
    logout() {
      try {
        this.$store.dispatch("admin/logout");
        this.$router.replace("/login");
        this.$toast.open({
          message: "Vous êtes déconnecté",
          type: "success",
        });
      } catch (err) {
        console.log(err);
        this.$toast.open({
          message: "Erreur lors de la déconnexion",
          type: "error",
        });
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
