import axios from "axios";

export default {

  async login({commit},payload){
    try {
      const res = await axios.post(`/user/login`,payload)
      commit('SET_USER',res.data.user)
      commit('SET_TOKEN',res.data.token)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  logout({commit}){
    commit('SET_USER',null)
    commit('SET_TOKEN',null)
    commit('SET_SOCKET',null,{root:true})
    return true
  },

  async getUser({state,commit}) {
    try {
      const res = await axios.get(`/user/${state.userId}`)
      commit('SET_USER',res.data)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async getDoctors() {
    try {
      const res = await axios.get(`/doctors`)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async getDoctorById(_, doctorId) {
    try {
      const res = await axios.get(`/doctors/${doctorId}`)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async getAppointments() {
    try {
      const res = await axios.get(`/appointments`)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async getPatients() {
    try {
      const res = await axios.get(`/patients`)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async updateProfile({commit,state},userData){
    try {
      const res = await axios.put(`/doctors/${state.userId}`,userData)
      commit('SET_USER',res.data)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async updatePassword({commit,state},{cpassword,password}){
    try {
      const res = await axios.post(`/user/changePassword`,{userId:state.userId,cpassword,password})
      commit('SET_USER',res.data.user)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async getContacts(){
    console.log("getting contacts");
    try {
      const res = await axios.get(`/contacts`)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async getContactsUser(){
    console.log("getting contacts User");
    try {
      const res = await axios.get(`/usercontacts`)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async disableUser(_,id){
    try{
      const res = await axios.put(`/user/disableUserAccount/${id}`)
      return res.data
    }
    catch(err){
      throw err.response.data.message
    }
  },

  async enableUser(_,id){
    try{
      const res = await axios.put(`/user/activateUserAccount/${id}`)
      return res.data
    }
    catch(err){
      throw err.response.data.message
    }
  },

  async validateDoctor(_,doctorId){
    try{
      const res = await axios.put(`/doctors/validateDoctorAccount/${doctorId}`)
      return res.data
    }
    catch(err){
      throw err.response.data.message
    }
  },
  async createRoom() {
    try {
      const res = await axios.post(`/chatRooms/`)
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async getChatRooms({ state }) {
    try {
      const res = await axios.get(`/chatRooms/${state.userId}`)
      return res.data.chatRooms
    } catch (err) {
      throw err.response.data.message
    }
  },

  async sendMessage(_, message) {
    try {
      console.log(message);
      const res = await axios.post(`/messages`, { message })
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async getMessages(_, roomId) {
    try {
      const res = await axios.get(`/messages/room/${roomId}`)
      return res.data.messages
    } catch (err) {
      throw err.response.data.message
    }
  },

  async sendReaction(_, {messageId,reactions}) {
    try {
      const res = await axios.put(`/messages/message/${messageId}`,{reactions})
      console.log(res.data);
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async updateMessage(_,{messageId,message}){
    try {
      const res = await axios.put(`/messages/message/${messageId}`,message)
      console.log(res.data);
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async deleteMessage(_,{messageId}){
    try {
      const res = await axios.delete(`/messages/message/${messageId}`)
      console.log(res.data);
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async getSubUsers(_){
    try {
      const res = await axios.get(`/user/subUsers`)
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async addSubUser(_,subUser){
    try {
      const res = await axios.post(`/user/subUsers`,subUser)
      console.log(res.data);
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async updateSubUser(_,subUser){
    try {
      const res = await axios.put(`/user/subUsers/${subUser._id}`,subUser)
      console.log(res.data);
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async deleteSubUser(_,id){
    try {
      console.log(id);
      const res = await axios.delete(`/user/subUsers/${id}`)
      console.log(res.data);
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },
};
