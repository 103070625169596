<template>
  <div class="col-md-3 text-center">
    <div
      style="background-color: #fff; border-radius: 10px; margin-bottom: 4%"
      class="cards-about"
    >
      <!-- <div
        class="p-2"
        v-if="!doctor.disabled"
        :style="`position:absolute;left:3.8%;top:5%;background-color:${
          doctor.doctorAdminValidation ? '#4bb54340' : '#ac424223'
        };border-radius:0 7px 7px 0`"
      >
        <i
          :class="`ni ni-${
            doctor.doctorAdminValidation ? 'check-bold' : 'fat-remove'
          }`"
          :style="`color: ${
            doctor.doctorAdminValidation ? '#4BB543' : '#ac4242'
          };`"
        ></i>
        <span
          :style="`color:${
            doctor.doctorAdminValidation ? '#4BB543' : 'red'
          };font-size:15px`"
          >{{ doctor.doctorAdminValidation ? "Vérifié" : "Non vérifié" }}</span
        >
      </div>
      <div
        v-else
        class="p-2"
        :style="`position:absolute;left:3.8%;top:5%;background-color:#ac424223;border-radius:0 7px 7px 0`"
      >
        <i :class="`ni ni-fat-remove`" :style="`color: #ac4242;`"></i>
        <span :style="`color:red;font-size:15px`">Désactivé</span>
      </div> -->
      <img
        :src="`${$store.state.admin.cloudinaryUrl}${doctor.photo}`"
        v-if="doctor.photo"
        alt=""
        style="
          height: 35%;
          width: 35%;
          border-radius: 50%;
          box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.4);
          margin-bottom: 10%;
          margin-top: 10%;
        "
      />
      <img
        src="https://res.cloudinary.com/dba2dcapc/image/upload/v1611628277/t%C3%A9l%C3%A9chargement_yo46pz.jpg"
        v-else-if="doctor.gender == 'homme'"
        alt=""
        style="
          height: 35%;
          width: 35%;
          border-radius: 50%;
          box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.4);
          margin-bottom: 10%;
          margin-top: 10%;
        "
      />
      <img
        src="https://res.cloudinary.com/dba2dcapc/image/upload/v1611690556/2ac5343c-2653-4e20-862a-6205d4e6fa61_lict1f.png"
        v-else
        alt=""
        style="
          height: 35%;
          width: 35%;
          border-radius: 50%;
          box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.4);
          margin-bottom: 10%;
          margin-top: 10%;
        "
      />
      <h3 class="primary-theme-color">
        {{ doctor.firstName }} {{ doctor.lastName }}
      </h3>
      <p id="spec">{{ doctor.specialty.join(", ") }}</p>
      <ul class="list-inline">
        <li>
          <base-button
            id="btnCard"
            style="background: transparent; border: none; margin-top: 4%"
            @click="showDoctorInfo(doctor)"
            ><i class="fa fa-info-circle" aria-hidden="true" id="plus"></i
          ></base-button>
        </li>
        <li v-if="role != 'viewer'">
          <base-button
            id="btnCard"
            style="background: transparent; border: none"
            @click="disableUser(doctor)"
            v-if="!doctor.disabled"
            ><i class="fa fa-minus-circle" aria-hidden="true" id="disable"></i
          ></base-button>
          <base-button
            id="btnCard"
            style="background: transparent; border: none"
            @click="enableUser(doctor)"
            v-else
            ><i class="fa fa-plus-circle" aria-hidden="true" id="verif"></i
          ></base-button>
        </li>
        <li v-if="role != 'viewer'">
          <base-button
            id="btnCard"
            style="background: transparent; border: none"
            :disabled="doctor.doctorAdminValidation"
            @click="validateDoctor(doctor)"
            ><i class="fa fa-check-circle" aria-hidden="true" id="verif"></i
          ></base-button>
        </li>
      </ul>
      <div
        style="
          display: flex;
          justify-content: center;
          padding-bottom: 10%;
          padding-top: 5%;
        "
      >
        <div
          class="p-2"
          v-if="!doctor.disabled"
          :style="`background-color:${
            doctor.doctorAdminValidation ? '#4bb54340' : '#ac424223'
          };border-radius:7px`"
        >
          <i
            :class="`ni ni-${
              doctor.doctorAdminValidation ? 'check-bold' : 'fat-remove'
            }`"
            :style="`color: ${
              doctor.doctorAdminValidation ? '#4BB543' : '#ac4242'
            };`"
          ></i>
          <span
            :style="`color:${
              doctor.doctorAdminValidation ? '#4BB543' : 'red'
            };font-size:15px; margin-right: auto; margin-left: auto;`"
            >{{
              doctor.doctorAdminValidation ? "Vérifié" : "Non vérifié"
            }}</span
          >
        </div>
        <div
          v-else
          class="p-2"
          :style="`background-color:#ac424223;border-radius:7px`"
        >
          <i :class="`ni ni-fat-remove`" :style="`color: #ac4242;`"></i>
          <span :style="`color:red;font-size:15px`">Désactivé</span>
        </div>
      </div>
    </div>
    <modal :show.sync="showModal" v-if="dataForDoctor">
      <h2 slot="header" class="modal-title" id="modal-title-default">
        Fiche Docteur
      </h2>
      <div class="row" style="height: 120px">
        <div class="col-lg-12">
          <div class="card-profile-image">
            <div v-if="!dataForDoctor.photo">
              <img
                v-if="dataForDoctor.gender == 'homme'"
                src="../../assets/profil-homme.png"
                class="rounded-circle"
              />
              <img
                v-else
                src="../../assets/profil-femme.png"
                class="rounded-circle"
              />
            </div>
            <div v-else>
              <img
                style="height: 140px; width: 140px"
                :src="`${$store.state.admin.cloudinaryUrl}${dataForDoctor.photo}`"
                class="rounded-circle"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row text-left">
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Nom du doctor"
            placeholder="Nom du docteur"
            input-classes="form-control-alternative"
            disabled
            :value="dataForDoctor.lastName"
          />
        </div>
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Prenom du doctor"
            placeholder="Prénom du docteur"
            input-classes="form-control-alternative"
            disabled
            :value="dataForDoctor.firstName"
          />
        </div>
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Téléphone du doctor"
            placeholder="Téléphonem du docteur"
            input-classes="form-control-alternative"
            disabled
            :value="dataForDoctor.phoneNumber"
          />
        </div>
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Email du doctor"
            placeholder="Email du doctor"
            input-classes="form-control-alternative"
            disabled
            :value="dataForDoctor.email"
          />
        </div>
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Date de naissance du doctor"
            placeholder="Date de naissance du doctor"
            input-classes="form-control-alternative"
            disabled
            :value="getDate(dataForDoctor.dateOfBirth)"
          />
        </div>
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Adresse du doctor"
            placeholder="Adresse du doctor"
            input-classes="form-control-alternative"
            disabled
            :value="dataForDoctor.address"
          />
        </div>
        <div class="col-lg-5">
          <label class="form-control-label"><b>Spécialités</b></label>
          <ul>
            <li
              v-for="(specialite, index) in dataForDoctor.specialty"
              :key="index"
            >
              {{ specialite }}
            </li>
          </ul>
        </div>
        <div class="col-lg-4">
          <label class="form-control-label"><b>Langues parlées</b></label>
          <ul>
            <li
              v-for="(language, index) in dataForDoctor.spokenLanguage"
              :key="index"
            >
              {{ language }}
            </li>
          </ul>
        </div>
        <div class="col-lg-3">
          <label class="form-control-label"><b>Social Media</b></label>
          <!-- <div v-for="(sm, index) in dataForDoctor.socialNetworks" :key="index"> -->
          <!-- <a :href="sm.profileLink" target="_blank">{{ sm.profileLink }}</a> -->
          <ul style="list-style: none">
            <li
              v-for="(element, index) in dataForDoctor.socialNetworks"
              :key="index"
            >
              <a
                :href="element.profileLink"
                target="_blank"
                class="docteur-links"
                v-html="iconSocialNetwork(element.socialNetworkName)"
              ></a>
              <!-- </div> -->
            </li>
          </ul>
        </div>
        <div class="col-lg-12">
          <label class="form-control-label"><b>Education</b></label>
          <ul>
            <li
              v-for="(education, index) in dataForDoctor.education"
              :key="index"
            >
              {{ education }}
            </li>
          </ul>
        </div>
        <div class="col-lg-12">
          <label class="form-control-label"><b>Présentation</b></label>
          <p>
            {{ dataForDoctor.presentation }}
          </p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    doctor: Object,
  },
  data: () => ({
    showModal: false,
    dataForDoctor: null,
  }),
  computed: {
    role() {
      return this.$store.state.admin.role;
    },
  },
  methods: {
    iconSocialNetwork(nameSocial) {
      var result = "";
      if (nameSocial == "facebook") {
        result =
          "<i class='fa fa-facebook' style='margin-right:8px;color:#4267B2; border:2px solid #4267B2;padding:12px;border-radius:50%;height:20px;width:20px;display:flex;align-items: center;justify-content: center; '></i> ";
      }
      if (nameSocial == "twitter") {
        result =
          "<i class='fa fa-twitter' style='margin-right:8px;color:#1DA1F2; border:2px solid #1DA1F2;padding:12px;border-radius:50%;height:20px;width:20px;display:flex;align-items: center;justify-content: center; '></i> ";
      }
      if (nameSocial == "linkedIn") {
        result =
          "<i class='fa fa-linkedin' style='margin-right:8px;color:#0e76a8; border:2px solid #0e76a8;padding:12px;border-radius:50%;height:20px;width:20px;display:flex;align-items: center;justify-content: center; '></i> ";
      }
      if (nameSocial == "WhatsApp") {
        result =
          "<i class='fa fa-whatsapp' style='margin-right:8px;color:#075e54; border:2px solid #075e54;padding:12px;border-radius:50%;height:20px;width:20px;display:flex;align-items: center;justify-content: center; '></i> ";
      }
      return result;
    },
    showDoctorInfo(doctor) {
      this.dataForDoctor = doctor;
      setTimeout(() => {
        this.showModal = true;
      }, 0);
    },
    async disableUser({ _id }) {
      try {
        let res = await this.$store.dispatch("admin/disableUser", _id);
        console.log(res);
        this.$emit("refresh");
        this.$toast.open({
          message: "Utilisateur desactivé",
          type: "success",
        });
      } catch (err) {
        console.log(err);
        this.$toast.open({
          message: "Erreur lors de la desactivation",
          type: "error",
        });
      }
    },

    async enableUser({ _id }) {
      try {
        let res = await this.$store.dispatch("admin/enableUser", _id);
        console.log(res);
        this.$emit("refresh");
        this.$toast.open({
          message: "Utilisateur activé",
          type: "success",
        });
      } catch (err) {
        console.log(err);
        this.$toast.open({
          message: "Erreur lors de l'activation",
          type: "error",
        });
      }
    },

    async validateDoctor({ _id }) {
      try {
        let res = await this.$store.dispatch("admin/validateDoctor", _id);
        this.$store.dispatch("socket/verifyDoctor", _id);
        this.$emit("refresh");
        this.$toast.open({
          message: "Docteur validé",
          type: "success",
        });
      } catch (err) {
        console.log(err);
        this.$toast.open({
          message: "Erreur lors de la validation",
          type: "error",
        });
      }
    },

    getDate(date) {
      return new Date(date).toLocaleDateString();
    },
  },
};
</script>

<style lang="css" src="./index.css"></style>