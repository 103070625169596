<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col-10">
          <h3
            style="color: #000"
            class="mb-0"
            :class="type === 'dark' ? 'text-white' : ''"
          >
            {{ title }}
          </h3>
        </div>
        <!-- <div class="col-6 text-right">
          <input class="form-control" placeholder="Recherche" />
        </div> -->

        <div class="col-2">
          <button
            class="btn"
            style="border: solid 0.1px; width: 95px"
            @click="modals.modal2 = !modals.modal2"
            v-if="role != 'viewer'"
          >
            Ajouter
          </button>
        </div>
      </div>
    </div>

    <div class="table-responsive" style="min-height: 30vh">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th class="text-center">Code coupon</th>
          <th class="text-center">Type</th>
          <th class="text-center">Etat</th>
          <th class="text-center">Date d'activation</th>
          <th class="text-center">Durée (mois)</th>
          <th class="text-center">Date d'expiration</th>
          <th class="text-center">Praticien</th>
        </template>

        <template v-slot="{ row }">
          <th scope="row" class="text-center">{{ row.voucherCode }}</th>
          <td class="text-center">
            <p>{{ row.type }}</p>
          </td>
          <td class="text-center">
            <p>{{ typeVoucher(row.isUsed) }}</p>
          </td>
          <td class="text-center">
            <p>{{ dateVoucher(row) }}</p>
          </td>
          <td class="text-center">
            <p>{{ row.duration || "" }}</p>
          </td>
          <td class="text-center">
            <p>
              {{ expirationDate(dateVoucher(row), row.duration, row.type) }}
            </p>
          </td>
          <td class="text-center">
            <p>{{ doctorName(row.user) }}</p>
          </td>
        </template>
      </base-table>
    </div>
    <modal :show.sync="modals.modal2">
      <div class="row">
        <div class="col-lg-12">
          <base-input
            label="Type de coupon"
            input-classes="form-control-alternative"
            ><select name="" id="" class="form-control" v-model="selectVoucher">
              <option value="Par Mois">Par Mois</option>
              <option value="Par An">Par An</option>
            </select>
          </base-input>
        </div>
        <div class="col-lg-12">
          <base-input
            label="nombre de mois / années"
            v-model="voucherDuration"
            type="number"
            input-classes="form-control-alternative"
          >
          </base-input>
        </div>
      </div>

      <div class="row" style="margin-top: 4%">
        <div class="col-lg-12"></div>
        <button
          class="btn"
          style="
            display: block;
            margin-left: auto;
            margin-right: auto;
            border: solid 0.5px;
          "
          @click="createVoucher()"
        >
          Ajouter un coupon
        </button>
      </div>
    </modal>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "project-table-coupon",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  computed: {
    role() {
      return this.$store.state.admin.role;
    },
  },
  data() {
    return {
      verif: false,
      modals: {
        modal: false,
        modal2: false,
      },
      tableData: [],
      dataPartner: [],
      dataNewPartner: [],
      selectVoucher: "Par Mois",
      voucherDuration: 6,
    };
  },
  created() {
    this.getVoucher();
  },
  methods: {
    expirationDate(start, duration, type) {
      if (start && duration) {
        var st = new Date(start);
        if (type == "Par Mois") {
          var exp = new Date(st.setMonth(duration));
          var month = exp.toLocaleString("en", { month: "short" });
          var day = exp.getUTCDate();
          var year = exp.getUTCFullYear();
          return day + "-" + month + "-" + year;
        } else {
          var exp = new Date();
          var exD = new Date(exp.setFullYear(st.getFullYear() + duration));
          var month = exD.toLocaleString("en", { month: "short" });
          var day = exD.getUTCDate();
          var year = exD.getUTCFullYear();
          return day + "-" + month + "-" + year;
        }
      }
    },
    doctorName(doctor) {
      if (doctor) {
        return doctor.firstName + " " + doctor.lastName;
      }
    },
    getVoucher() {
      axios
        .get("https://algedoc-backend.herokuapp.com/api/vouchers/")
        .then((response) => {
          console.log(response);
          this.tableData = response.data;
          this.modals.modal2 = false;
        })
        .catch((error) => {
          console.log("get all partners");
          console.log(error);
        });
    },
    typeVoucher(element) {
      if (element == false) {
        return "Non utilisé";
      } else {
        return "Utilisé";
      }
    },
    dateVoucher(element) {
      if (element.updatedAt != element.createdAt) {
        const d = new Date(element.updatedAt);
        const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
        const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
        const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
        return `${da}-${mo}-${ye}`;
      }
    },
    createVoucher() {
      axios
        .post("https://algedoc-backend.herokuapp.com/api/vouchers/generate", {
          type: this.selectVoucher,
          duration: this.voucherDuration,
        })
        .then((response) => {
          this.getVoucher();
          this.modals.modal2 = false;
          this.$toast.open({
            message: "Coupon bien crée",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("new partner");
          console.log(error);
          this.$toast.open({
            message: "Coupon non crée",
            type: "error",
          });
        });
    },
  },
};
</script>
<style>
.details-fiche {
  padding: 5%;
  background: #82846d;
}
.details-fiche .form-control-label {
  color: #fff !important;
}

.description-fiche {
  display: block;
  width: 100%;
  /* height: calc(1.5em + 1.25rem + 2px); */
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
</style>
