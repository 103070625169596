<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3
            style="color: #000"
            class="mb-0"
            :class="type === 'dark' ? 'text-white' : ''"
          >
            Liste des sous-comptes
          </h3>
        </div>
        <base-button size="md" @click="openAddModal()">Ajouter</base-button>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="subUsers"
        v-if="subUsers && subUsers.length"
      >
        <template slot="columns">
          <th class="text-center">Nom & Prénom</th>
          <th class="text-center">Email</th>
          <th class="text-center">Rôle</th>
          <th class="text-center">Mot de passe</th>
          <th class="text-center">Actions</th>
        </template>

        <template v-slot="{ row, index }">
          <th class="text-center">
            {{ row.firstName || "" }} {{ row.lastName || "" }}
          </th>
          <td class="text-center">
            {{ row.email }}
          </td>
          <td class="text-center">
            {{ roles[row.role] }}
          </td>
          <td class="text-center">******</td>
          <td class="text-center">
            <base-button size="sm" @click="edit(index)">Modifier</base-button>
            <base-button
              size="sm"
              style="background: #cc000e !important; border-color: #cc000e"
              @click="deleteSubUser(row._id)"
              >Supprimer</base-button
            >
          </td>
        </template>
      </base-table>
    </div>
    <modal :show.sync="modals.update" v-if="currentSubUser">
      <div class="row">
        <div class="col-lg-12">
          <base-input
            label="Prénom"
            input-classes="form-control-alternative"
            v-model="currentSubUser.firstName"
          >
          </base-input>
        </div>
        <div class="col-lg-12">
          <base-input
            label="Nom"
            input-classes="form-control-alternative"
            v-model="currentSubUser.lastName"
          >
          </base-input>
        </div>
        <div class="col-lg-12">
          <base-input
            label="email"
            input-classes="form-control-alternative"
            v-model="currentSubUser.email"
          >
          </base-input>
        </div>
        <div class="col-lg-12">
          <base-input label="Rôle" input-classes="form-control-alternative">
            <select class="form-control" v-model="currentSubUser.role">
              <option value="admin">Admin</option>
              <option value="editor">Éditeur</option>
              <option value="viewer">Visiteur</option>
            </select>
          </base-input>
        </div>
        <div class="col-lg-12">
          <base-input
            label="Mot de passe"
            type="password"
            input-classes="form-control-alternative"
            placeholder="(Pas de modifications)"
          >
          </base-input>
        </div>
      </div>

      <div class="row" style="margin-top: 4%">
        <button
          class="btn"
          style="
            background: #cc000e !important;
            border-color: #cc000e;
            margin-left: auto;
            margin-right: auto;
            color: white;
          "
          @click="updateSubUser()"
        >
          Enregistrer
        </button>
      </div>
    </modal>

    <modal :show.sync="modals.add" v-if="subUserToAdd">
      <div class="row">
        <div class="col-lg-12">
          <base-input
            label="Prénom"
            input-classes="form-control-alternative"
            v-model="subUserToAdd.firstName"
          >
          </base-input>
        </div>
        <div class="col-lg-12">
          <base-input
            label="Nom"
            input-classes="form-control-alternative"
            v-model="subUserToAdd.lastName"
          >
          </base-input>
        </div>
        <div class="col-lg-12">
          <base-input
            label="email"
            input-classes="form-control-alternative"
            v-model="subUserToAdd.email"
          >
          </base-input>
        </div>
        <div class="col-lg-12">
          <base-input label="Rôle" input-classes="form-control-alternative">
            <select class="form-control" v-model="subUserToAdd.role">
              <option value="admin">Admin</option>
              <option value="editor">Éditeur</option>
              <option value="viewer">Visiteur</option>
            </select>
          </base-input>
        </div>
        <div class="col-lg-12">
          <base-input
            label="Mot de passe"
            type="password"
            input-classes="form-control-alternative"
            v-model="subUserToAdd.password"
          >
          </base-input>
        </div>
      </div>

      <div class="row" style="margin-top: 4%">
        <button
          class="btn"
          style="
            background: #cc000e !important;
            border-color: #cc000e;
            margin-left: auto;
            margin-right: auto;
            color: white;
          "
          @click="addSubUser()"
        >
          Ajouter
        </button>
      </div>
    </modal>
  </div>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
export default {
  components: { BaseInput },
  props: {
    subUsers: Array,
  },
  data: () => ({
    type: null,
    modals: {
      update: false,
      add: false,
    },
    currentSubUser: null,
    subUserToAdd: {},
    roles: {
      admin: "admin",
      editor: "editeur",
      viewer: "visiteur",
    },
  }),
  methods: {
    edit(index) {
      this.currentSubUser = { ...this.subUsers[index] };
      this.modals.update = true;
    },

    openAddModal() {
      this.modals.add = true;
    },

    checkForEmptyFields(user) {
      for (let key in user) if (!user[key]) delete user[key];
    },

    async addSubUser() {
      try {
        const res = await this.$store.dispatch(
          "admin/addSubUser",
          this.subUserToAdd
        );
        this.$toast.open({
          message: "Utilisateur ajouté",
          type: "success",
        });
        this.subUserToAdd = null;
        this.modals.add = false;
        this.$emit("refresh");
        this.$router.go();
      } catch (err) {
        console.log(err);
        this.$toast.open({
          message: "Erreur lors de l'ajout",
          type: "error",
        });
      }
    },

    async updateSubUser() {
      try {
        this.checkForEmptyFields(this.currentSubUser);
        const res = await this.$store.dispatch(
          "admin/updateSubUser",
          this.currentSubUser
        );
        this.$toast.open({
          message: "Utilisateur modifié",
          type: "success",
        });
        this.currentSubUser = null;
        this.modals.update = false;
        this.$emit("refresh");
      } catch (err) {
        console.log(err);
        this.$toast.open({
          message: "Erreur lors de la modification",
          type: "error",
        });
      }
    },

    async deleteSubUser(id) {
      try {
        const res = await this.$store.dispatch("admin/deleteSubUser", id);
        this.$toast.open({
          message: "Utilisateur supprimé",
          type: "success",
        });
        this.currentSubUser = null;
        this.$emit("refresh");
      } catch (err) {
        console.log(err);
        this.$toast.open({
          message: "Erreur lors de la suppression",
          type: "error",
        });
      }
    },
  },
};
</script>
<style>
</style>
