<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 style="color: #000;" class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>

      </div>
    </div>

    <div class="table-responsive" style="min-height: 30vh">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th class="text-center">Email</th>
          <th class="text-center">Recevoir emails</th>
          <th></th>
        </template>

        <template v-slot="{ row }">
          <th scope="row" class="text-center">{{ row.email }}</th>
          <td class="text-center">
            <i
              :class="`ni ni-${row.sendMail ? 'check-bold' : 'fat-remove'}`"
              :style="`color: ${
                row.sendMail ? '#4BB543' : '#ac4242'
              }; font-size: 25px;`"
            ></i>
          </td>
          <td class="text-center" v-if="role != 'viewer'">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <!-- <a class="dropdown-item" href="#" @click="modals.modal = true" -->
                <a
                  class="dropdown-item"
                  href="#"
                  @click="disableNewsLetters(row._id)"
                  v-if='row.sendMail'>Désactiver</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click="enableNewsLetters(row._id)"
                   v-if='!row.sendMail'>Activé</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click="deleteNewsLetters(row._id)"
                  v-if="role == 'admin'"
                  >Supprimer</a
                >
              </template>
            </base-dropdown>
          </td>
        </template>
      </base-table>
    </div>

  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "projects-table-news-letters",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      verif: false,
      modals: {
        modal: false,
      },
      tableData: [],
    };
  },
  computed:{
    role(){
      return this.$store.state.admin.role
    }
  },
  created() {
    axios
      .get("https://algedoc-backend.herokuapp.com/api/newsletters/")
      .then((response) => {
        this.tableData = response.data.newsletters;
      })
      .catch((error) => {
        console.log("get all news letters");
        console.log(error);
      });
  },
  methods: {
        enableNewsLetters(id) {
      axios
        .put("https://algedoc-backend.herokuapp.com/api/newsletters/" + id, {
          sendMail: true,
        })
        .then(() => {
          this.tableData = this.tableData.map((news) => {
            if (news._id == id) return { ...news, sendMail: true };
            else return news;
          });
          this.$toast.open({
            message: "Newsletter activé",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("disable news letters");
          console.log(error);
          this.$toast.open({
            message: "Erreur lors de la desactivation",
            type: "error",
          });
        });
    },
    disableNewsLetters(id) {
      axios
        .put("https://algedoc-backend.herokuapp.com/api/newsletters/" + id, {
          sendMail: false,
        })
        .then(() => {
          this.tableData = this.tableData.map((news) => {
            if (news._id == id) return { ...news, sendMail: false };
            else return news;
          });
          this.$toast.open({
            message: "Newsletter desactivé",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("disable news letters");
          console.log(error);
          this.$toast.open({
            message: "Erreur lors de la desactivation",
            type: "error",
          });
        });
    },
    deleteNewsLetters(id) {
      axios
        .delete("https://algedoc-backend.herokuapp.com/api/newsletters/" + id)
        .then((response) => {
          this.tableData = response.data.newsletters;
          this.$toast.open({
            message: "Newsletter supprimé",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("delete news letters");
          console.log(error);
          this.$toast.open({
            message: "Erreur lors de la suppression",
            type: "error",
          });
        });
    },
  },
};
</script>
<style>
.details-fiche {
  padding: 5%;
  background: #82846d;
}
.details-fiche .form-control-label {
  color: #fff !important;
}

.description-fiche {
  display: block;
  width: 100%;
  /* height: calc(1.5em + 1.25rem + 2px); */
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
</style>
