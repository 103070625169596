<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-7">
        <div class="card bg-secondary shadow border-0">
          <div class="card-header bg-transparent text-center">
           <h2 style="color: #cc000e!important">Se connecter</h2>
            <!-- <div class="btn-wrapper text-center">
                            <a href="#" class="btn btn-neutral btn-icon">
                                <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                                <span class="btn-inner--text">Github</span>
                            </a>
                            <a href="#" class="btn btn-neutral btn-icon">
                                <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
                                <span class="btn-inner--text">Google</span>
                            </a>
                        </div> -->
          </div>
          <div class="card-body px-lg-5 py-lg-5">
            <form role="form">
              <base-input
                class="input-group-alternative mb-3"
                placeholder="Email"
                addon-left-icon="ni ni-email-83"
                v-model="model.email"
              >
              </base-input>

              <base-input
                class="input-group-alternative"
                placeholder="Mot de passe"
                type="password"
                addon-left-icon="ni ni-lock-circle-open"
                v-model="model.password"
              >
              </base-input>

              <div class="text-center">
               <base-button
                style="background: #cc000e !important; border: none"
                class="my-4"
                @click="login"
                >Se connecter</base-button
              >
              </div>
            </form>
          </div>
        </div>
        <div class="row mt-3">
          <!--  -->
        </div>
      </div>
      <!-- </form> -->
    </div>
    <!-- </div> -->
    <!-- <div class="row mt-3 justify-content-center">
      <div class="col-lg-5 col-md-7">
        <div class="row">
          <div class="col-6">
            <a href="#" class="text-light"><small>Forgot password?</small></a>
          </div>
          <div class="col-6 text-right">
            <router-link to="/register" class="text-light"
              ><small>Create new account</small></router-link
            >
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async login() {
      try {
        const roles = ['admin','viewer','editor']
        const res = await this.$store.dispatch("admin/login", this.model);
        console.log(res);
        if (roles.indexOf(res.user.role) == -1) {
          console.log("error");
          this.$toast.open({
            message: "Admin : Vous n'êtes pas autorisé",
            type: "error",
          });
        } else {
          await this.$router.replace("/dashboard");
          this.$store.dispatch('socket/connectSocket')
          this.$toast.open({
            message: "Bienvenu Admin",
            type: "success",
          });
        }
      } catch (err) {
        console.log(err);
        this.$toast.open({
          message: "Admin : Email ou Mot de passe érroné",
          type: "error",
        });
      }
    },
  },
};
</script>
<style>
</style>
