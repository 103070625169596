var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"Argon","title":"Argon"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-tv-2',
          path: '/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Patients',
          icon: 'ni ni-single-02 ',
          path: '/patient',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Praticiens',
          icon: 'ni ni-single-02 ',
          path: '/praticiens',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Contact Utilisateurs',
          icon: 'ni ni-email-83 ',
          path: '/contactuser',
        }}}),(_vm.role != 'viewer')?_c('sidebar-item',{attrs:{"link":{
          name: 'Messagerie instantannée',
          icon: 'ni ni-email-83 ',
          path: '/chat',
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Offres',
          icon: 'ni ni-credit-card',
          path: '/Offre',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Coupons',
          icon: 'ni ni-credit-card',
          path: '/coupon',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Blog',
          icon: 'ni ni-single-copy-04',
          path: '/blog',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Newsletters',
          icon: 'ni ni-send ',
          path: '/newsletters',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Partenaires',
          icon: 'ni ni-single-02 ',
          path: '/partenaires',
        }}}),(_vm.role == 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'Profil',
          icon: 'ni ni-single-02 ',
          path: '/profil',
        }}}):_vm._e(),(_vm.role == 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'Rôles',
          icon: 'ni ni-single-02 ',
          path: '/roles',
        }}}):_vm._e(),(_vm.role == 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'Mot de passe',
          icon: 'ni ni-lock-circle-open',
          path: '/changePassword',
        }}}):_vm._e()],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }