import Vue from 'vue'
import Vuex from 'vuex'
import admin from './AdminModule'
import socket from './SocketModule'

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    admin,
    socket
  },
})

