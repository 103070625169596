import { io } from 'socket.io-client'
export default {
  connectSocket({ commit, rootState, dispatch }) {
    const socket = io("https://algedoc-backend.herokuapp.com/");

    socket.on('connect', () => {
      socket.emit('joinConnectedUsers', { userId: rootState.admin.userId })
      console.log("socket connected");
    })

    commit('SET_SOCKET', socket)
  },

  closeSocket({ commit, state, rootState }) {
    if (state.socket) {
      state.socket.emit('disconnected', { userId: rootState.admin.userId })
      console.log("closing socket");
      state.socket.close()
      commit('SET_SOCKET', null)
    }
  },

  verifyDoctor({ state }, doctorId) {
    if (state.socket)
      state.socket.emit('verifyDoctor', { doctorId })
  }
}