<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-doc opacity-8"></span>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <!-- <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img src="../assets/admin.png" class="rounded-circle" />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
            ></div>
            <div class="card-body" style="margin-top: 17%">
              <div class="text-center">
                <h3>Administrateur</h3>
              </div>
            </div>
          </div>
          <base-button type="warning" block class="mt-4" @click="updateUser"
            >Enregistrer</base-button
          >
        </div> -->

        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 style="color: #000;" class="mb-0">Information du compte</h3>
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">
                  Information utilisateur
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <base-input
                        alternative=""
                        label="Email"
                        type="email"
                        placeholder="contact@alerDoc.com"
                        input-classes="form-control-alternative"
                        v-model="user.email"
                      />
                    </div>
                  </div>
                </div>
                  <base-button style="background:#cc000e!important;border:none;" block class="mt-4" @click="updateUser"
            >Enregistrer</base-button
          >
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "user-profile",
  computed: {
    user() {
      return this.$store.state.admin.user;
    },
  },
  methods: {
    async updateUser() {
      try {
        const res = await this.$store.dispatch("admin/updateProfile", {
          email: this.user.email,
        });
        console.log(res);
        this.$toast.open({
            message: "La modification a été effectué",
            type: "success",
          });
      } catch (err) {
        console.log(err);
        this.$toast.open({
          message: "Admin : Erreur lors de modification",
          type: "error",
        });
      }
    },
  },
};
</script>
<style>
.bg-gradient-doc {
  background: linear-gradient(87deg, #cc000e 0, #889720 100%) !important;
}
</style>
