<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <!-- <div class="row">
        <div class="col-xl-4 col-lg-4">
          <stats-card
            title="Docteurs"
            type="gradient-info"
            sub-title="2"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 54.8%</span
              >
              <span class="text-nowrap">Depuis le mois dernier</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-4">
          <stats-card
            title="Docteurs verifiés"
            type="gradient-orange"
            sub-title="12"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 12.18%</span
              >
              <span class="text-nowrap">Depuis le mois dernier</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-4">
          <stats-card
            title="Docteurs non verifiés"
            type="gradient-green"
            sub-title="31"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <span class="text-danger mr-2"
                ><i class="fa fa-arrow-down"></i> 5.72%</span
              >
              <span class="text-nowrap">Depuis le mois dernier</span>
            </template>
          </stats-card>
        </div>
      </div> -->
      <base-input class="mt-5 mb-0 col-md-2">
        <h3 style="color: white">Type d'affichage</h3>
        <select class="form-control" v-model="displayType">
          <option value="table">Tableau</option>
          <option value="card">Cartes</option>
        </select>
      </base-input>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row" v-if="displayType == 'table'">
        <div class="col">
          <doctors-table title="Praticiens" :data="doctors" @refresh="getDoctors"></doctors-table>
        </div>
      </div>
      <div class="row" v-else>
        <DoctorCard v-for="(doctor,i) in doctors" :key="i" :doctor="doctor" @refresh="getDoctors"/>
      </div>
    </div>
  </div>
</template>
<script>
import DoctorsTable from "./Tables/DoctorsTable";
import DoctorCard from "./DoctorCard/DoctorCard";
export default {
  name: "tables",
  components: {
    DoctorCard,
    DoctorsTable,
  },
  data() {
    return {
      doctors: [],
      displayType: "table",
    };
  },
  methods: {
    async getDoctors() {
      console.log("getting doctors");
      try{
        this.doctors = await this.$store.dispatch("admin/getDoctors");
      }
      catch(err){
        console.log(err);
      }
    },
  },
  mounted() {
    this.getDoctors();
  },
};
</script>
<style></style>
