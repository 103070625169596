<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col-10">
          <h3 style="color: #000;" class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <!-- <div class="col-6 text-right">
          <input class="form-control" placeholder="Recherche" />
        </div> -->

        <div class="col-2">
          <button
            class="btn"
            style="border: solid 0.1px; width: 95px"
            v-on:click="modals.modal2 = !modals.modal2"
            v-if="role != 'viewer'"
          >
            Ajouter
          </button>
        </div>
      </div>
    </div>

    <div class="table-responsive" style="min-height: 30vh">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th class="text-center">Lien</th>
          <th class="text-center">Image</th>
          <th></th>
        </template>

        <template v-slot="{ row }">
          <th scope="row" class="text-center">{{ row.link }}</th>
          <td class="text-center">
            <div class="row" style="height: 100px">
              <div class="col-lg-12">
                <div>
                  <img
                    style="height: 100px; width: 100px"
                    :src="`${$store.state.admin.cloudinaryUrl}${row.image}`"
                    class="rounded-circle"
                  />
                </div>
              </div>
            </div>
          </td>
          <td class="text-center" v-if="role != 'viewer'">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a
                  class="dropdown-item"
                  href="#"
                  @click="openPopupUpdate(row._id)"
                  v-if="role != 'viewer'"
                  >Modifier</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click="deletePartner(row._id)"
                  v-if="role == 'admin'"
                  >Supprimer</a
                >
              </template>
            </base-dropdown>
          </td>
        </template>
      </base-table>
    </div>
    <modal :show.sync="modals.modal">
      <div class="row" style="height: 100px; margin-bottom: 4%">
        <div class="col-lg-12">
          <div>
            <img
              style="
                height: 100px;
                width: 100px;
                display: block;
                margin-left: auto;
                margin-right: auto;
              "
              :src="`${$store.state.admin.cloudinaryUrl}${dataPartner.image}`"
              class="rounded-circle"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <base-input
            alternative=""
            label="Lien de partenaire"
            placeholder="Lien de partenaire"
            input-classes="form-control-alternative"
            v-model="dataPartner.link"
          />
        </div>
        <div class="col-lg-12"></div>
        <button
          class="btn btn-danger"
          style="display: block; margin-left: auto; margin-right: auto"
          @click="openUploadModalUpdate"
        >
          Choisir votre image
        </button>
      </div>

      <div class="row" style="margin-top: 4%">
        <div class="col-lg-12"></div>
        <button
          class="btn"
          style="
            display: block;
            margin-left: auto;
            margin-right: auto;
            border: solid 0.5px;
          "
          @click="updatePartner(dataPartner._id)"
        >
          Enregistrer vos modifications
        </button>
      </div>
    </modal>
    <modal :show.sync="modals.modal2">
      <div class="row">
        <div class="col-lg-12">
          <base-input
            alternative=""
            label="Lien de partenaire"
            placeholder="Lien de partenaire"
            input-classes="form-control-alternative"
            v-model="dataNewPartner.link"
          />
        </div>
        <div class="col-lg-12"></div>
        <button
          class="btn btn-danger"
          style="display: block; margin-left: auto; margin-right: auto"
          @click="openUploadModalCreate"
        >
          Choisir votre image
        </button>
      </div>

      <div class="row" style="margin-top: 4%">
        <div class="col-lg-12"></div>
        <button
          class="btn"
          style="
            display: block;
            margin-left: auto;
            margin-right: auto;
            border: solid 0.5px;
          "
          @click="createPartner()"
        >
          Enregistrer vos modifications
        </button>
      </div>
    </modal>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "projects-table-partenaires",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  computed: {
    role() {
      return this.$store.state.admin.role;
    },
  },
  data() {
    return {
      verif: false,
      modals: {
        modal: false,
        modal2: false,
      },
      tableData: [],
      dataPartner: [],
      dataNewPartner: [],
    };
  },
  created() {
    axios
      .get("https://algedoc-backend.herokuapp.com/api/partners/")
      .then((response) => {
        this.tableData = response.data.partners;
        this.modals.modal2 = false;
      })
      .catch((error) => {
        console.log("get all partners");
        console.log(error);
      });
  },
  methods: {
    openUploadModalUpdate() {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dba2dcapc", upload_preset: "upzzyy75" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.dataPartner.image = this.publicId;
            }
          }
        )
        .open();
    },
    openUploadModalCreate() {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dba2dcapc", upload_preset: "upzzyy75" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.dataNewPartner.image = this.publicId;
            }
          }
        )
        .open();
    },
    openPopupUpdate(id) {
      this.modals.modal = true;
      axios
        .get("https://algedoc-backend.herokuapp.com/api/partners/" + id)
        .then((response) => {
          this.dataPartner = response.data.partner;
        })
        .catch((error) => {
          console.log("get all partners");
          console.log(error);
        });
    },
    updatePartner(id) {
      axios
        .put("https://algedoc-backend.herokuapp.com/api/partners/" + id, {
          link: this.dataPartner.link,
          image: this.dataPartner.image,
        })
        .then((response) => {
          this.tableData = response.data.partners;
          this.$toast.open({
            message: "Partenaire supprimé",
            type: "success",
          });
          this.modals.modal = false;
        })
        .catch((error) => {
          console.log("disable Partenaires");
          console.log(error);
          this.$toast.open({
            message: "Erreur lors de la modification",
            type: "error",
          });
        });
    },
    createPartner() {
      axios
        .post("https://algedoc-backend.herokuapp.com/api/partners/", {
          link: this.dataNewPartner.link,
          image: this.dataNewPartner.image,
        })
        .then((response) => {
          this.modals.modal2 = false;
          this.tableData = response.data.partners;
          this.dataNewPartner.link = "";
          this.$toast.open({
            message: "Partenaire supprimé",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("new partner");
          console.log(error);
          this.$toast.open({
            message: "Erreur lors de la création",
            type: "error",
          });
        });
    },
    deletePartner(id) {
      axios
        .delete("https://algedoc-backend.herokuapp.com/api/partners/" + id)
        .then((response) => {
          this.tableData = response.data.partners;
          this.$toast.open({
            message: "Partenaire supprimé",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("delete partner");
          console.log(error);
          this.$toast.open({
            message: "Erreur lors de la suppression",
            type: "error",
          });
        });
    },
  },
};
</script>
<style>
.details-fiche {
  padding: 5%;
  background: #82846d;
}
.details-fiche .form-control-label {
  color: #fff !important;
}
.description-fiche {
  display: block;
  width: 100%;
  /* height: calc(1.5em + 1.25rem + 2px); */
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
</style>
