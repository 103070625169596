<template>
  <div id="app">
    <notifications></notifications>
    <router-view/>
  </div>
</template>

<script>
export default {

  methods:{
    async checkUser(){
      try{
        console.log("checking for user");
        const roles = ['admin','viewer','editor']
        const user = await this.$store.dispatch('admin/getUser')
        if(roles.indexOf(user.role) == -1){
          this.$store.dispatch('admin/logout')
          this.$router.replace('/login')
          console.log("user is not an admin");
        }
        else{
          console.log("user is valid");
          this.$store.dispatch('socket/connectSocket')
        }
      }
      catch(err){
          console.log(err);
          this.$store.dispatch('admin/logout')
          this.$router.replace('/login')
          console.log("user is invalid");
      }
    }
  },
  mounted(){
    if(this.$store.getters['admin/isLoggedIn']){
      this.checkUser()
    }
  }
}
</script>
