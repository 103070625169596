import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import store from './store'
Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: 'dashboard',
      component: DashboardLayout,
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue')
        },

        {
          path: '/doc',
          name: 'doc',
          component: () => import(/* webpackChunkName: "demo" */ './views/doc.vue')
        },
        {
          path: '/icons',
          name: 'icons',
          component: () => import(/* webpackChunkName: "demo" */ './views/Icons.vue')
        },
        {
          path: '/profil',
          name: 'profil',
          component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue')
        },
        {
          path: '/maps',
          name: 'maps',
          component: () => import(/* webpackChunkName: "demo" */ './views/Maps.vue')
        },
        {
          path: '/tables',
          name: 'tables',
          component: () => import(/* webpackChunkName: "demo" */ './views/Tables.vue')
        },
        {
          path: '/patient',
          name: 'patients',
          component: () => import(/* webpackChunkName: "demo" */ './views/patient.vue')
        },
        {
          path: '/contactdoctor',
          name: 'contact docteurs',
          component: () => import(/* webpackChunkName: "demo" */ './views/contactDoctor.vue')
        },
        {
          path: '/contactuser',
          name: 'contact utilisateurs',
          component: () => import(/* webpackChunkName: "demo" */ './views/contactUser.vue')
        },
        {
          path: '/offre',
          name: 'offres',
          component: () => import(/* webpackChunkName: "demo" */ './views/Offre/index.vue')
        },
        {
          path: '/coupon',
          name: 'coupons',
          component: () => import(/* webpackChunkName: "demo" */ './views/coupon.vue')
        },

        {
          path: '/blog',
          name: 'blog',
          component: () => import(/* webpackChunkName: "demo" */ './views/blog.vue')
        },
        {
          path: '/newsletters',
          name: 'newsletters',
          component: () => import(/* webpackChunkName: "demo" */ './views/NewsLetters.vue')
        },
        {
          path: '/partenaires',
          name: 'partenaires',
          component: () => import(/* webpackChunkName: "demo" */ './views/Partenaires.vue')
        },
        {
          path: '/praticiens',
          name: 'praticiens',
          component: () => import(/* webpackChunkName: "demo" */ './views/doctor.vue')
        },
        {
          path: '/changePassword',
          name: 'changer mot de passe',
          component: () => import(/* webpackChunkName: "demo" */ './views/ChangePassword.vue'),
          beforeEnter:(to,from,next)=>{
            if(store.getters['admin/role'] != "admin")
              next({
                path:'/dashboard'
              })
            else
              next()
          }
        },
        {
          path: '/chat',
          name: 'chat',
          component: () => import(/* webpackChunkName: "demo" */ './views/Chat.vue')
        },
        {
          path: '/roles',
          name: 'roles',
          component: () => import(/* webpackChunkName: "demo" */ './views/Roles.vue'),
          beforeEnter:(to,from,next)=>{
            if(store.getters['admin/role'] != "admin")
              next({
                path:'/dashboard'
              })
            else
              next()
          }
        },
      ],
      beforeEnter:(to,from,next) => {
        if(!store.getters['admin/isLoggedIn'])
          next({
            path:'/login'
          })
        else
          next()
      }
    },
    {
      path: '/login',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue')
        },
        {
          path: '/register',
          name: 'register',
          component: () => import(/* webpackChunkName: "demo" */ './views/Register.vue')
        }
      ],
      beforeEnter:(to,from,next)=>{
        if(store.getters['admin/isLoggedIn'])
          next({
            path:'/dashboard'
          })
        else next()
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})