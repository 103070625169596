<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
                   >
            <span class="mask bg-gradient-doc opacity-8"></span>
        </base-header>

          <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <project-table-partenaires title="Partenaires"></project-table-partenaires>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
 import ProjectTablePartenaires from './Tables/ProjectTablePartenaires'
  export default {

    name: 'contact',
      components: {
      ProjectTablePartenaires
    },
    data() {
      return {

      }
    },
  };
</script>
<style>
.bg-gradient-doc{
    background:linear-gradient(87deg, #cc000e 0, #889720 100%) !important
}
.subject-label{
    color: #82846D;
    font-size: 0.875rem;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 0.5rem;
}
.subject-option{
display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #cad1d7;
    border-radius: 0.375rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    border: 0;
}
</style>
