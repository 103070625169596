<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <RolesTable></RolesTable>
        </div>
      </div>
      <div class="row mt-5">
                <div class="col">
                    <SubUsersTable :subUsers="subUsers" @refresh="getSubUsers"></SubUsersTable>
                </div>
            </div>
    </div>
  </div>
</template>
<script>
import RolesTable from "./Tables/RolesTable";
import SubUsersTable from "./Tables/SubUsersTable";
export default {
  name: "tables",
  components: {
    RolesTable,
    SubUsersTable
  },
  data:()=>({
      subUsers:[]
  }),
  computed:{
    user(){
      return this.$store.state.admin.user
    }
  },
  methods:{
      async getSubUsers(){
          this.subUsers = (await this.$store.dispatch('admin/getSubUsers'))
          .filter(user => user.email != this.user.email)
      }
  },
  mounted(){
      this.getSubUsers()
  }
  
};
</script>
<style></style>
