<template>
  <div class="row">
    <div class="col-xl-6" style="margin-top: 7%">
      <div class="row">
        <div class="col-xl-12">
          <base-input
            alternative=""
            label="Sujet"
            placeholder="Sujet de l'email"
            input-classes="form-control-alternative"
            v-model="Subject"
          />
        </div>
        <div class="col-xl-12">
          <base-input
            alternative=""
            label="Titre"
            placeholder="Titre de l'email"
            input-classes="form-control-alternative"
            v-model="title"
          />
        </div>
        <div class="col-xl-12">
          <base-input alternative="" label="Description">
            <textarea
              rows="4"
              class="form-control form-control-alternative"
              placeholder="Description ..."
              v-model="Description"
            ></textarea>
          </base-input>
        </div>
        <div class="col-xl-12" style="display: grid">
          <base-button
            class="mt-5"
            block
            style="
              background-color: #cc000e;
              border: none;
              width: 50%;
              justify-self: center;
            "
            @click="openUploadModal"
            >Choisir l'image</base-button
          >
        </div>
      </div>
    </div>
    <div class="col-xl-6">
      <div class="es-wrapper-color" style="background-color: #ffffff">
        <!--[if gte mso 9]>
          <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
            <v:fill type="tile" color="#ffffff"></v:fill>
          </v:background>
        <![endif]-->
        <table
          class="es-wrapper"
          style="
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            border-collapse: collapse;
            border-spacing: 0px;
            padding: 0;
            margin: 0;
            width: 100%;
            height: 100%;
            background-repeat: repeat;
            background-position: center top;
          "
          width="100%"
          cellspacing="0"
          cellpadding="0"
        >
          <tr style="border-collapse: collapse">
            <td valign="top" style="padding: 0; margin: 0">
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-content"
                align="center"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                "
              >
                <tr style="border-collapse: collapse">
                  <td
                    class="es-adaptive"
                    align="center"
                    style="padding: 0; margin: 0"
                  >
                    <table
                      class="es-content-body"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        border-collapse: collapse;
                        border-spacing: 0px;
                        background-color: transparent;
                        width: 100%;
                      "
                      cellspacing="0"
                      cellpadding="0"
                      bgcolor="#ffffff"
                      align="center"
                    >
                      <tr style="border-collapse: collapse">
                        <td align="left" style="padding: 10px; margin: 0">
                          <!--[if mso]><table style="width:580px"><tr><td style="width:280px" valign="top"><![endif]-->
                          <table
                            class="es-left"
                            cellspacing="0"
                            cellpadding="0"
                            align="left"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              border-collapse: collapse;
                              border-spacing: 0px;
                              float: left;
                            "
                          >
                            <tr style="border-collapse: collapse">
                              <td
                                align="left"
                                style="padding: 0; margin: 0; width: 280px"
                              >
                                <table
                                  width="100%"
                                  cellspacing="0"
                                  cellpadding="0"
                                  role="presentation"
                                  style="
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: collapse;
                                    border-spacing: 0px;
                                  "
                                >
                                  <tr style="border-collapse: collapse">
                                    <td
                                      class="es-infoblock es-m-txt-c"
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        line-height: 14px;
                                        font-size: 12px;
                                        color: #cccccc;
                                      "
                                    >
                                      <p
                                        style="
                                          margin: 0;
                                          -webkit-text-size-adjust: none;
                                          -ms-text-size-adjust: none;
                                          mso-line-height-rule: exactly;
                                          font-size: 12px;
                                          font-family: helvetica,
                                            'helvetica neue', arial, verdana,
                                            sans-serif;
                                          line-height: 14px;
                                          color: #cccccc;
                                        "
                                      >
                                        <br />
                                      </p>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>

                          <!--[if mso]></td><td style="width:20px"></td><td style="width:280px" valign="top"><![endif]-->
                          <table
                            class="es-right"
                            cellspacing="0"
                            cellpadding="0"
                            align="right"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              border-collapse: collapse;
                              border-spacing: 0px;
                              float: right;
                            "
                          >
                            <tr style="border-collapse: collapse">
                              <td
                                align="left"
                                style="padding: 0; margin: 0; width: 280p"
                              >
                                <table
                                  width="100%"
                                  cellspacing="0"
                                  cellpadding="0"
                                  role="presentation"
                                  style="
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: collapse;
                                    border-spacing: 0px;
                                  "
                                >
                                  <tr style="border-collapse: collapse">
                                    <td
                                      align="right"
                                      class="es-infoblock es-m-txt-c"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        line-height: 14px;
                                        font-size: 12px;
                                        color: #cccccc;
                                      "
                                    >
                                      <p
                                        style="
                                          margin: 0;
                                          -webkit-text-size-adjust: none;
                                          -ms-text-size-adjust: none;
                                          mso-line-height-rule: exactly;
                                          font-size: 12px;
                                          font-family: helvetica,
                                            'helvetica neue', arial, verdana,
                                            sans-serif;
                                          line-height: 14px;
                                          color: #cccccc;
                                        "
                                      >
                                        <a
                                          href="#"
                                          target="_blank"
                                          class="view"
                                          style="
                                            -webkit-text-size-adjust: none;
                                            -ms-text-size-adjust: none;
                                            mso-line-height-rule: exactly;
                                            font-family: helvetica,
                                              'helvetica neue', arial, verdana,
                                              sans-serif;
                                            font-size: 12px;
                                            text-decoration: none;
                                            color: #cccccc;
                                          "
                                          >A</a
                                        >lgerieDok
                                      </p>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>

                          <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table
                class="es-header"
                cellspacing="0"
                cellpadding="0"
                align="center"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                  background-color: transparent;
                  background-repeat: repeat;
                  background-position: center top;
                "
              >
                <tr style="border-collapse: collapse">
                  <td align="center" style="padding: 0; margin: 0">
                    <table
                      class="es-header-body"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        border-collapse: collapse;
                        border-spacing: 0px;
                        background-color: transparent;
                      "
                      cellspacing="0"
                      cellpadding="0"
                      align="center"
                    >
                      <tr style="border-collapse: collapse">
                        <td align="left" style="padding: 0; margin: 0">
                          <table
                            width="100%"
                            cellspacing="0"
                            cellpadding="0"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              border-collapse: collapse;
                              border-spacing: 0px;
                            "
                          >
                            <tr style="border-collapse: collapse">
                              <td
                                valign="top"
                                align="center"
                                style="padding: 0; margin: 0"
                              >
                                <table
                                  width="100%"
                                  cellspacing="0"
                                  cellpadding="0"
                                  role="presentation"
                                  style="
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: collapse;
                                    border-spacing: 0px;
                                  "
                                >
                                  <tr style="border-collapse: collapse">
                                    <td
                                      align="center"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        padding-bottom: 20px;
                                        font-size: 0px;
                                      "
                                    >
                                      <a
                                        href="https://algeriedok.onrender.com/"
                                        target="_blank"
                                        style="
                                          -webkit-text-size-adjust: none;
                                          -ms-text-size-adjust: none;
                                          mso-line-height-rule: exactly;
                                          font-family: helvetica,
                                            'helvetica neue', arial, verdana,
                                            sans-serif;
                                          font-size: 14px;
                                          text-decoration: none;
                                          color: #f6a1b4;
                                        "
                                        ><img
                                          src="https://ocizza.stripocdn.email/content/guids/CABINET_d89938d94bc820afd8c5a8ed9d4d05c6/images/12191614548591176.png"
                                          alt
                                          style="
                                            display: block;
                                            border: 0;
                                            outline: none;
                                            text-decoration: none;
                                            -ms-interpolation-mode: bicubic;
                                          "
                                          height="103"
                                      /></a>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table
                class="es-content"
                cellspacing="0"
                cellpadding="0"
                align="center"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                "
              >
                <tr style="border-collapse: collapse">
                  <td align="center" style="padding: 0; margin: 0">
                    <table
                      class="es-content-body"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        border-collapse: collapse;
                        border-spacing: 0px;
                        background-color: transparent;
                      "
                      cellspacing="0"
                      cellpadding="0"
                      align="center"
                    >
                      <tr style="border-collapse: collapse">
                        <td align="left" style="padding: 0; margin: 0">
                          <table
                            width="100%"
                            cellspacing="0"
                            cellpadding="0"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              border-collapse: collapse;
                              border-spacing: 0px;
                            "
                          >
                            <tr style="border-collapse: collapse">
                              <td
                                valign="top"
                                align="center"
                                style="padding: 0; margin: 0"
                              >
                                <table
                                  style="
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: separate;
                                    border-spacing: 0px;
                                    border-radius: 3px;
                                    background-color: #fcfcfc;
                                  "
                                  width="100%"
                                  cellspacing="0"
                                  cellpadding="0"
                                  bgcolor="#fcfcfc"
                                  role="presentation"
                                >
                                  <tr style="border-collapse: collapse">
                                    <td
                                      class="es-m-txt-l"
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        padding-left: 20px;
                                        padding-right: 20px;
                                        padding-top: 30px;
                                      "
                                    >
                                      <h2
                                        v-if="title != ''"
                                        style="
                                          margin: 0;
                                          line-height: 31px;
                                          mso-line-height-rule: exactly;
                                          font-family: roboto, 'helvetica neue',
                                            helvetica, arial, sans-serif;
                                          font-size: 26px;
                                          font-style: normal;
                                          font-weight: normal;
                                          color: #000000;
                                        "
                                      >
                                        {{ title }}
                                      </h2>

                                      <h2
                                        v-else
                                        style="
                                          margin: 0;
                                          line-height: 31px;
                                          mso-line-height-rule: exactly;
                                          font-family: roboto, 'helvetica neue',
                                            helvetica, arial, sans-serif;
                                          font-size: 26px;
                                          font-style: normal;
                                          font-weight: normal;
                                          color: #000000;
                                        "
                                      >
                                        Buenos dias !
                                      </h2>
                                    </td>
                                  </tr>

                                  <tr style="border-collapse: collapse">
                                    <td
                                      bgcolor="#fcfcfc"
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        padding-top: 10px;
                                        padding-left: 20px;
                                        padding-right: 20px;
                                      "
                                    >
                                      <p
                                        v-if="Description != ''"
                                        style="
                                          margin: 0;
                                          -webkit-text-size-adjust: none;
                                          -ms-text-size-adjust: none;
                                          mso-line-height-rule: exactly;
                                          font-size: 14px;
                                          overflow-wrap: break-word;
                                          font-family: helvetica,
                                            'helvetica neue', arial, verdana,
                                            sans-serif;
                                          line-height: 21px;
                                          color: #82846d;
                                        "
                                      >
                                        {{ Description }}
                                      </p>

                                      <p
                                        v-else
                                        style="
                                          margin: 0;
                                          -webkit-text-size-adjust: none;
                                          -ms-text-size-adjust: none;
                                          mso-line-height-rule: exactly;
                                          font-size: 14px;
                                          font-family: helvetica,
                                            'helvetica neue', arial, verdana,
                                            sans-serif;
                                          line-height: 21px;
                                          color: #82846d;
                                        "
                                      >
                                        Our newsletters system is a magnifecent
                                        way to be up to date with the latest
                                        medical news, life hacks and researches.
                                        We thank you for you subscription and
                                        the interest in our services.
                                      </p>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr style="border-collapse: collapse">
                        <td
                          style="
                            padding: 0;
                            margin: 0;
                            padding-left: 20px;
                            padding-right: 20px;
                            padding-top: 30px;
                            background-color: #fcfcfc;
                          "
                          bgcolor="#fcfcfc"
                          align="left"
                        >
                          <table
                            width="100%"
                            cellspacing="0"
                            cellpadding="0"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              border-collapse: collapse;
                              border-spacing: 0px;
                            "
                          >
                            <tr style="border-collapse: collapse">
                              <td
                                valign="top"
                                align="center"
                                style="padding: 0; margin: 0"
                              >
                                <table
                                  style="
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: separate;
                                    border-spacing: 0px;
                                    border-color: #efefef;
                                    border-style: solid;
                                    border-width: 1px;
                                    border-radius: 3px;
                                    background-color: #ffffff;
                                  "
                                  width="100%"
                                  cellspacing="0"
                                  cellpadding="0"
                                  bgcolor="#ffffff"
                                  role="presentation"
                                >
                                  <tr style="border-collapse: collapse">
                                    <td
                                      align="center"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        font-size: 0px;
                                      "
                                    >
                                      <img
                                        v-if="Image != ''"
                                        class="adapt-img"
                                        :src="Image"
                                        alt
                                        style="
                                          display: block;
                                          border: 0;
                                          outline: none;
                                          text-decoration: none;
                                          -ms-interpolation-mode: bicubic;
                                        "
                                        width="100%"
                                      />
                                      <img
                                        v-else
                                        class="adapt-img"
                                        src="https://ocizza.stripocdn.email/content/guids/CABINET_d89938d94bc820afd8c5a8ed9d4d05c6/images/94411611361767005.jpg"
                                        alt
                                        style="
                                          display: block;
                                          border: 0;
                                          outline: none;
                                          text-decoration: none;
                                          -ms-interpolation-mode: bicubic;
                                        "
                                        width="100%"
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table
                class="es-content"
                cellspacing="0"
                cellpadding="0"
                align="center"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                "
              >
                <tr style="border-collapse: collapse">
                  <td align="center" style="padding: 0; margin: 0">
                    <table
                      class="es-content-body"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        border-collapse: collapse;
                        border-spacing: 0px;
                        background-color: #fcfcfc;
                      "
                      cellspacing="0"
                      cellpadding="0"
                      bgcolor="#fcfcfc"
                      align="center"
                    >
                      <tr style="border-collapse: collapse">
                        <td
                          align="left"
                          style="
                            margin: 0;
                            padding-left: 20px;
                            padding-right: 20px;
                            padding-bottom: 25px;
                            padding-top: 40px;
                          "
                        >
                          <!--[if mso]><table style="width:560px" cellpadding="0"
                            cellspacing="0"><tr><td style="width:271px" valign="top"><![endif]-->
                          <table
                            class="es-left"
                            cellspacing="0"
                            cellpadding="0"
                            align="left"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              border-collapse: collapse;
                              border-spacing: 0px;
                              float: left;
                            "
                          >
                            <tr style="border-collapse: collapse">
                              <td
                                class="es-m-p0r es-m-p20b"
                                align="center"
                                style="padding: 0; margin: 0; width: 251px"
                              >
                                <table
                                  width="100%"
                                  cellspacing="0"
                                  cellpadding="0"
                                  style="
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: collapse;
                                    border-spacing: 0px;
                                  "
                                >
                                  <tr style="border-collapse: collapse">
                                    <td
                                      align="center"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        display: none;
                                      "
                                    ></td>
                                  </tr>
                                </table>
                              </td>
                              <td
                                class="es-hidden"
                                style="padding: 0; margin: 0; width: 20px"
                              ></td>
                            </tr>
                          </table>

                          <!--[if mso]></td><td style="width:136px" valign="top"><![endif]-->
                          <table
                            class="es-left"
                            cellspacing="0"
                            cellpadding="0"
                            align="left"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              border-collapse: collapse;
                              border-spacing: 0px;
                              float: left;
                            "
                          >
                            <tr style="border-collapse: collapse">
                              <td
                                class="es-m-p20b"
                                align="center"
                                style="padding: 0; margin: 0; width: 136px"
                              >
                                <table
                                  width="100%"
                                  cellspacing="0"
                                  cellpadding="0"
                                  style="
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: collapse;
                                    border-spacing: 0px;
                                  "
                                >
                                  <tr style="border-collapse: collapse">
                                    <td
                                      align="center"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        display: none;
                                      "
                                    ></td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>

                          <!--[if mso]></td><td style="width:20px"></td><td style="width:133px" valign="top"><![endif]-->
                          <table
                            class="es-right"
                            cellspacing="0"
                            cellpadding="0"
                            align="right"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              border-collapse: collapse;
                              border-spacing: 0px;
                              float: right;
                            "
                          >
                            <tr style="border-collapse: collapse">
                              <td
                                align="center"
                                style="padding: 0; margin: 0; width: 133px"
                              >
                                <table
                                  width="100%"
                                  cellspacing="0"
                                  cellpadding="0"
                                  style="
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: collapse;
                                    border-spacing: 0px;
                                  "
                                >
                                  <tr style="border-collapse: collapse">
                                    <td
                                      align="center"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        display: none;
                                      "
                                    ></td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>

                          <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table
                class="es-content"
                cellspacing="0"
                cellpadding="0"
                align="center"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                "
              >
                <tr style="border-collapse: collapse">
                  <td align="center" style="padding: 0; margin: 0">
                    <table
                      class="es-content-body"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        border-collapse: collapse;
                        border-spacing: 0px;
                        background-color: transparent;
                      "
                      cellspacing="0"
                      cellpadding="0"
                      bgcolor="#ffffff"
                      align="center"
                    >
                      <tr style="border-collapse: collapse">
                        <td align="left" style="padding: 0; margin: 0">
                          <table
                            width="100%"
                            cellspacing="0"
                            cellpadding="0"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              border-collapse: collapse;
                              border-spacing: 0px;
                            "
                          >
                            <tr style="border-collapse: collapse">
                              <td
                                valign="top"
                                align="center"
                                style="padding: 0; margin: 0"
                              >
                                <table
                                  style="
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: collapse;
                                    border-spacing: 0px;
                                    background-color: #fff4f7;
                                  "
                                  width="100%"
                                  cellspacing="0"
                                  cellpadding="0"
                                  bgcolor="#fff4f7"
                                >
                                  <tr style="border-collapse: collapse">
                                    <td
                                      align="center"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        display: none;
                                      "
                                    ></td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table
                class="es-content"
                cellspacing="0"
                cellpadding="0"
                align="center"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                "
              >
                <tr style="border-collapse: collapse">
                  <td
                    style="padding: 0; margin: 0; background-color: #666666"
                    bgcolor="#666666"
                    align="center"
                  >
                    <table
                      class="es-content-body"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        border-collapse: collapse;
                        border-spacing: 0px;
                        background-color: transparent;
                      "
                      cellspacing="0"
                      cellpadding="0"
                      bgcolor="#ffffff"
                      align="center"
                    >
                      <tr style="border-collapse: collapse">
                        <td align="left" style="padding: 0; margin: 0">
                          <table
                            width="100%"
                            cellspacing="0"
                            cellpadding="0"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              border-collapse: collapse;
                              border-spacing: 0px;
                            "
                          >
                            <tr style="border-collapse: collapse">
                              <td
                                valign="top"
                                align="center"
                                style="padding: 0; margin: 0"
                              >
                                <table
                                  style="
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: separate;
                                    border-spacing: 0px;
                                    background-color: #fff4f7;
                                    border-radius: 3px;
                                  "
                                  width="100%"
                                  cellspacing="0"
                                  cellpadding="0"
                                  bgcolor="#fff4f7"
                                  role="presentation"
                                >
                                  <tr style="border-collapse: collapse">
                                    <td
                                      bgcolor="#fff4f7"
                                      align="center"
                                      style="
                                        margin: 0;
                                        padding-top: 5px;
                                        padding-bottom: 5px;
                                        padding-left: 20px;
                                        padding-right: 20px;
                                        font-size: 0;
                                      "
                                    >
                                      <table
                                        width="100%"
                                        height="100%"
                                        cellspacing="0"
                                        cellpadding="0"
                                        border="0"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tr style="border-collapse: collapse">
                                          <td
                                            style="
                                              padding: 0;
                                              margin: 0;
                                              border-bottom: 1px solid #fff4f7;
                                              background: #ffffff none repeat
                                                scroll 0% 0%;
                                              height: 1px;
                                              width: 100%;
                                              margin: 0px;
                                            "
                                          ></td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="col-xl-12" style="display: grid">
      <base-button
        class="mt-5"
        block
        style="
          background-color: #cc000e;
          border: none;
          justify-self: center;
          width: 20%;
        "
        @click="sendEmail()"
        >Envoyer</base-button
      >
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "mail-content",
  data() {
    return {
      verif: false,
      title: "",
      Subject: "",
      Description: "",
      Image: "",
      publicId: null,
      modals: {
        modal: false,
      },
      tableData: [],
    };
  },
  methods: {
    sendEmail() {
      axios
        .post(
          "https://algedoc-backend.herokuapp.com/api/newsletters/sendEmail",
          {
            subject: this.Subject,
            title: this.title,
            description: this.Description,
            image: this.Image,
          }
        )
        .then((response) => {
          this.$toast.open({
            message: "Email bien envoyé",
            type: "success",
          });
          this.Subject = "";
          this.title = "";
          this.Description = "";
          this.Image = "";
        })
        .catch((error) => {
          this.$toast.open({
            message: "Erreur los de l'envoie",
            type: "error",
          });
          console.log(error);
        });
    },
    openUploadModal() {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dba2dcapc", upload_preset: "upzzyy75" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result);
              this.Image = result.info.url;
              this.publicId = result.info.public_id;
            }
          }
        )
        .open();
    },
  },
};
</script>
<style>
.rollover div {
  font-size: 0;
}
#outlook a {
  padding: 0;
}
.ExternalClass {
  width: 100%;
}
.ExternalClass,
.ExternalClass p,
.ExternalClass span,
.ExternalClass font,
.ExternalClass td,
.ExternalClass div {
  line-height: 100%;
}
.es-button {
  mso-style-priority: 100 !important;
  text-decoration: none !important;
}
a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}
.es-desk-hidden {
  display: none;
  float: left;
  overflow: hidden;
  width: 0;
  max-height: 0;
  line-height: 0;
  mso-hide: all;
}
</style>
