<template>
  <div class="card shadow">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 style="color: #000" class="mb-0">Liste des Rôles</h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        tbody-classes="list"
        :data="roles"
        style="min-height: 25vh"
        v-if="roles && roles.length > 0"
      >
        <template slot="columns">
          <th class="text-center">Rôle</th>
          <th class="text-center">Description</th>
        </template>

        <template v-slot="{ row }">
          <td class="text-center">
            {{ row.name }}
          </td>
          <td class="text-center">
            {{ row.description }}
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    roles: [
      {
        name: "Admin",
        description: "Ajout / Modification / Suppression ",
      },
      {
        name: "Éditeur",
        description: "Ajout / Modification ",
      },
      {
        name: "Visiteur",
        description: "lecteur",
      },
    ],
  }),
};
</script>