<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 style="color: #000;" class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>

      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="data"
        style="min-height: 25vh"
        v-if="data && data.length > 0"
      >
        <template slot="columns">
          <th class="text-center">Nom du patient</th>
          <th class="text-center">Homme/Femme</th>
          <th class="text-center">Téléphone</th>
          <th class="text-center">Email</th>
          <th></th>
        </template>

        <template v-slot="{ row }">
          <td class="text-center">{{ row.firstName }} {{ row.lastName }}</td>

          <td class="text-center">
            {{ row.gender }}
          </td>

          <td class="text-center">
            {{ row.phoneNumber }}
          </td>
          <td class="text-center">
            {{ row.email }}
          </td>
          <td class="text-center" v-if="role == 'admin'">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a class="dropdown-item" href="#">Supprimer</a>
              </template>
            </base-dropdown>
          </td>
        </template>
      </base-table>
      <div class="p-4" v-else>
        <h5>Pas de patients</h5>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "patients-table",
  props: {
    type: {
      type: String,
    },
    data: Array,
    title: String,
  },
  data() {
    return {
      verif: false,
      modals: {
        modal: false,
      },
    };
  },
};
</script>
<style>
.details-fiche {
  padding: 5%;
  background: #82846d;
}
.details-fiche .form-control-label {
  color: #fff !important;
}

.description-fiche {
  display: block;
  width: 100%;
  /* height: calc(1.5em + 1.25rem + 2px); */
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
</style>
