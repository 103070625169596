import axios from "axios";

export default {
  name: "user-profile",
  data() {
    return {
      priceToDisplay:0,
      modal: false,
      discount: '',
      newPrice: '',
      offerId:0,
      chosen: 'mois',
      // offersData: [
      //   {
      //     name: "Gratuit",
      //     price: "0",
      //     duration: "mois",
      //     features: [
      //       "Gestion du calendrier",
      //       "Gestion des rendez-vous",
      //       "Statistique sur les rendez-vous",
      //     ]
      //   },
      //   {
      //     name: "Premium",
      //     price: "1",
      //     duration: "an",
      //     features: [
      //       "Statistique sur les rendez-vous",
      //       "Gestion du calendrier",
      //       "Gestion des rendez-vous",
      //       "Chat avec l'administrateur",
      //     ]
      //   },
      //   {
      //     name: "Gratuit",
      //     price: "0",
      //     duration: "an",
      //     features: [
      //       "Gestion du calendrier",
      //       "Gestion des rendez-vous",
      //       "Statistique sur les rendez-vous",
      //     ]
      //   },
      //   {
      //     name: "Premium",
      //     price: "0",
      //     duration: "mois",
      //     features: [
      //       "Statistique sur les rendez-vous",
      //       "Gestion du calendrier",
      //       "Gestion des rendez-vous",
      //       "Chat avec l'administrateur"
      //     ]
      //   },
      // ],
      // offers: []
    };
  },
  computed:{
    role(){
      return this.$store.state.admin.role
    }
  },
  created() {
    this.getOffers()
  },
  methods: {
    modify() {
      axios
      .put(
        "https://algedoc-backend.herokuapp.com/api/offers/",{
          percentage:this.discount,
          price:this.newPrice,
          offerId:this.offerId
        }
      )
      .then((response) => {
        this.$toast.open({
          message: "prix bien modifier",
          type: "success",
        });
        this.getOffers()
      })
      .catch((error) => {
        this.$toast.open({
          message: "Erreur lors de la modification",
          type: "error",
        });
      });
      // this.offersData.forEach(elem => {
      //   if (elem.name == "Premium") {
      //     if (elem.duration == "mois") {
      //       elem.price = this.newPrice
      //     } else {
      //       elem.price = (this.newPrice - (this.newPrice * this.discount / 100)) * 12
      //     }
      //   }
      // })
      // this.offers.sort(function compare(a, b) {
      //   if (a.price < b.price)
      //     return -1;
      //   if (a.price > b.price)
      //     return 1;
      //   return 0;
      // })
      // this.modal = false
      
    },
    choosePeriod(choice) {
      this.chosen = choice
    },
    getOffers() {
      // GET REQUEST
      // this.switchOffers()
      axios
      .get(
        "https://algedoc-backend.herokuapp.com/api/offers/",
      )
      .then((response) => {
        this.offerId=response.data[0]._id
        if (this.chosen == "an") {
          console.log(response)
          this.priceToDisplay=(response.data[0].price*12)-(response.data[0].price*12)*response.data[0].percentage /100
        }else{
          this.priceToDisplay=response.data[0].price
        }
      
      })
      .catch((error) => {
      });
    },
    // switchOffers() {
    //   this.offers = new Array();
    //   this.offersData.forEach(element => {
    //     if (element.duration == this.chosen) {
    //       this.offers.push(element)
    //     }
    //   });
    //   this.offers.sort(function compare(a, b) {
    //     if (a.price < b.price)
    //       return -1;
    //     if (a.price > b.price)
    //       return 1;
    //     return 0;
    //   })
    // }
  }
};