<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-4 col-lg-4">
          <stats-card
            title="Docteur"
            type="gradient-info"
            sub-title="2"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 54.8%</span
              >
              <span class="text-nowrap">Depuis le mois dernier</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-4">
          <stats-card
            title="Docteur verifié"
            type="gradient-orange"
            sub-title="12"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 12.18%</span
              >
              <span class="text-nowrap">Depuis le mois dernier</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-4">
          <stats-card
            title="Docteur non verifié"
            type="gradient-green"
            sub-title="31"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <span class="text-danger mr-2"
                ><i class="fa fa-arrow-down"></i> 5.72%</span
              >
              <span class="text-nowrap">Depuis le mois dernier</span>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-md-3 text-center " style="padding: 2%;">
          <div
            style="background-color: #fff;border-radius: 4px;padding: 3% 3% 5% 3%;"
            class="cards-about"
          >
            <img
             src="img/theme/team-4-800x800.jpg"
              alt=""
              style="height:40%;width: 40%;border-radius:50%; box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.4);margin-bottom:10%"
            />
            <h3 class="primary-theme-color">Omar Mhiri</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur.
            </p>
            <base-button style="background:#cc000e;border:none;margin-top:0%">Voir plus</base-button>
            <div class="row" style="margin-top:8%;border-top:1px solid #cc000e;margin-left:-8px;margin-right:-8px;padding-top:5%">
              <div class="col-md-6" style="border-right:1px solid #cc000e"><i class="ni ni-calendar-grid-58" style="color:#cc000e"></i> Historique</div>
               <div class="col-md-6"><i class="ni ni-mobile-button" style="color:#cc000e"></i> Numéro</div>
          </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from '../components/BaseButton.vue';
//   import ProjectsTableDocteur from './Tables/ProjectsTableDocteur'
export default {
  name: "tables",
  components: {
    BaseButton
    //   ProjectsTableDocteur
  },
};
</script>
<style>
.primary-theme-color{
        color:#cc000e!important;
}
.cards-about{
  box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.2);
  }

  .cards-about:hover{
    box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.4);
  }
  .cards-about:hover p{
    color:#cc000e!important;
  }

</style>
