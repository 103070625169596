<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-doc opacity-8"></span>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col tab-btn-newsletter">
          <div style="display: grid; justify-items: center; margin-bottom: 2%">
            <ul class="nav nav-pills" role="tablist">
              <li class="nav-item tab-newsletter">
                <a
                  class="nav-link"
                  data-toggle="pill"
                  href="#newsletters"
                  @click="(tableShow = true), (contentShow = false)"
                  :class="tableShow ? 'active' : ''"
                  >Liste des abonnés</a
                >
              </li>
              <li class="nav-item tab-newsletter">
                <a
                  class="nav-link"
                  data-toggle="pill"
                  href="#newsletters"
                  @click="(tableShow = false), (contentShow = true)"
                  :class="contentShow ? 'active' : ''"
                  >Rédiger le contenu de l'email</a
                >
              </li>
            </ul>
          </div>
          <project-table-news-letters
            title="News Letters"
            v-if="tableShow"
          ></project-table-news-letters>
          <mail-content v-if="contentShow" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProjectTableNewsLetters from "./Tables/ProjectTableNewsLetters";
import MailContent from "./MailContent/MailContent";
export default {
  name: "contact",
  components: {
    ProjectTableNewsLetters,
    MailContent,
  },
  data() {
    return {
      tableShow: true,
      contentShow: false,
      model: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        country: "",
        zipCode: "",
        about: "",
      },
    };
  },
};
</script>
<style>
.bg-gradient-doc {
  background: linear-gradient(87deg, #cc000e 0, #889720 100%) !important;
}
.subject-label {
  color: #82846d;
  font-size: 0.875rem;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.subject-option {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0;
}
.tab-btn-newsletter .nav-pills {
  width: 70%;
}
.tab-newsletter {
  width: 50%;
}
.nav-pills .nav-link {
  font-weight: bold;
  padding-top: 13px;
  text-align: center;
  color: #000;
  border-radius: 30px;
}
.nav-pills .nav-link.active {
  background: #fff;
  color: #cc000e;
}
</style>
