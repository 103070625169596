<template>
  <div class="chatPage">
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
 
    </base-header>
    <div class="container-fluid mt--7">
      <chat-window
        v-if="userId"
        :current-user-id="userId"
        :rooms="rooms"
        :messages="messages"
        @send-message="sendMessage"
        @fetch-messages="getRoomInfo"
        :loading-rooms="loadingRooms"
        :rooms-loaded="true"
        :messages-loaded="messagesloaded"
        @open-file="openFile"
        @send-message-reaction="sendReaction"
        @edit-message="editMessage"
        @delete-message="deleteMessage"
      />
    </div>
    <modal :show.sync="showModal" v-if="showModal" class="imgModal">
      <img :src="activeImage" />
    </modal>
  </div>
</template>

<script>
import ChatWindow from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import axios from "axios";
export default {
  components: {
    ChatWindow,
  },
  data() {
    return {
      rooms: [],
      messages: [],
      messagesloaded: false,
      roomId: null,
      otherUser: null,
      loadingRooms: true,
      showModal: false,
      activeImage: "",
    };
  },
  computed: {
    userId() {
      return this.$store.state.admin.userId;
    },
  },
  methods: {
    getRoomInfo({ room }) {
      this.roomId = room.roomId;
      this.messagesloaded = false;
      this.getMessages(room.roomId);
    },

    async getMessages(roomId) {
      try {
        if (roomId) {
          this.messages = await this.$store.dispatch(
            "admin/getMessages",
            roomId
          );
          this.messagesloaded = true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getRooms() {
      try {
        const rooms = await this.$store.dispatch("admin/getChatRooms");
        this.loadingRooms = false;
        this.rooms = rooms.map((room) => ({
          ...room,
          users: this.getRoomUsers(room),
          roomId: room._id,
          roomName: this.getRoomName(room),
          avatar: this.getOtherUserAvatar(room),
        }));
      } catch (error) {
        console.log(error);
        this.loadingRooms = false;
      }
    },

    getOtherUser(users) {
      return users.find((user) => user._id != this.userId);
    },

    getOtherUserAvatar({ users }) {
      const otherUser = this.getOtherUser(users);
      return !otherUser.photo
        ? "https://res.cloudinary.com/dba2dcapc/image/upload/v1611628277/t%C3%A9l%C3%A9chargement_yo46pz.jpg"
        : `${this.$store.state.admin.cloudinaryUrl}${otherUser.photo}`;
    },

    getRoomName({ users }) {
      const otherUser = this.getOtherUser(users);
      return `${otherUser.firstName || ""} ${otherUser.lastName || ""}`;
    },

    getRoomUsers({ users }) {
      return users.map((user) => ({
        username: `${user.firstName} ${user.lastName}`,
        ...user,
      }));
    },

    async sendMessage(message) {
      try {
        message.sender_id = this.userId;
        if (message.file) {
          message.file.url = await this.uploadFile(message.file);
        }
        const { messageToSend } = await this.$store.dispatch(
          "admin/sendMessage",
          message
        );
        this.messages = [...this.messages, messageToSend];
      } catch (err) {
        console.log(err);
      }
    },

    async sendReaction({ roomId, messageId, reaction, remove }) {
      let messageIndex = this.messages.findIndex(
        (message) => message._id == messageId
      );
      let message = this.messages[messageIndex];
      message.reactions = message.reactions
        ? { ...message.reactions, [reaction.name]: [this.userId] }
        : { [reaction.name]: [this.userId] };
      try {
        await this.$store.dispatch("admin/sendReaction", {
          messageId,
          reactions: message.reactions,
        });
      } catch (err) {
        console.log(err);
      }
      this.messages[messageIndex] = message;
      this.messages = [...this.messages];
      console.log(this.messages);
    },

    async editMessage({ messageId, newContent }) {
      try {
        const message = await this.$store.dispatch("admin/updateMessage", {
          messageId,
          message: { content: newContent },
        });
        this.editLocalMessage(messageId, newContent);
      } catch (err) {
        console.log(err);
      }
    },

    editLocalMessage(messageId, newContent) {
      let messageIndex = this.messages.findIndex(
        (message) => message._id == messageId
      );
      let message = this.messages[messageIndex];
      message.content = newContent;
      this.messages[messageIndex] = message;
      this.messages = [...this.messages];
    },

    async deleteMessage({ messageId }) {
      try {
        const res = await this.$store.dispatch("admin/deleteMessage", {
          messageId,
        });
        this.deleteLocalMessage(messageId);
      } catch (err) {
        console.log(err);
      }
    },

    deleteLocalMessage(messageId) {
      let index = this.messages.findIndex(
        (message) => message._id.toString() == messageId
      );
      this.messages.splice(index, 1);
      this.messages = [...this.messages];
    },

    async uploadFile(file) {
      const formData = new FormData();
      formData.append("file", file.blob);
      formData.append("upload_preset", "upzzyy75");
      return await axios
        .post(`https://api.cloudinary.com/v1_1/dba2dcapc/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return res.data.secure_url;
        })
        .catch((err) => console.log(err));
    },

    openFile({ message, action }) {
      switch (action) {
        case "preview":
          // const win = window.open(message.file.url, "_blank");
          // win.focus();
          this.showModal = true;
          this.activeImage = message.file.url;
          break;
        case "download":
          axios
            .get(message.file.url, {
              responseType: "blob",
            })
            .then((res) => {
              const url = window.URL.createObjectURL(res.data);
              const a = document.createElement("a");
              a.href = url;
              a.download = message.file.name;
              a.click();
            })
            .catch((err) => console.log(err));
      }
    },
  },

  async mounted() {
    await this.getRooms();
    setInterval(() => {
      this.getMessages(this.roomId);
    }, 5000);
  },
};
</script>

<style lang="scss">
.vac-room-header{
  z-index:0 !important;
}
.chatPage .modal .modal-dialog {
  justify-content: center;
  .modal-content {
    height: auto;
    width: auto;
    .modal-body {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-height: 80vh;
      }
    }
  }
}
</style>