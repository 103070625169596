export default {
    SET_USER(state, user) {
        state.user = user;
        state.userId = (user) ?  user._id :  null;
        state.role = user && user.role
        if (user){
          localStorage.setItem("algerieDok-U",user._id);
          localStorage.setItem("algerieDok-R",user.role);
        } 
        else{
          localStorage.removeItem("algerieDok-U");
          localStorage.removeItem("algerieDok-R");
        }
      },

      SET_TOKEN(state,token){
        if(token){
          state.token = token
          localStorage.setItem('algerieDok-T',token)
        }
        else
          localStorage.removeItem('algerieDok-T')
      }
};
