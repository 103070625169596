<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3
            style="color: #000"
            class="mb-0"
            :class="type === 'dark' ? 'text-white' : ''"
          >
            {{ title }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <div class="container" style="float: right">
        <div class="row">
          <div class="col-lg-2"></div>
          <div class="col-lg-3">
            <select
              class="form-control"
              v-model="displayVerif"
              @change="searchResult('displayVerif')"
            >
              <option value="tous">Tous</option>
              <option value="verif">Vérifié</option>
              <option value="nverif">Non vérifié</option>
            </select>
          </div>
          <div class="col-lg-3">
            <select
              class="form-control"
              v-model="displayType"
              @change="searchResult('displayType')"
            >
              <option value="tous">Tous</option>
              <option value="active">Activé</option>
              <option value="desactive">Désactivé</option>
            </select>
          </div>
          <div class="col-lg-3">
            <base-input
              @input="searchResult('serachName')"
              placeholder="Nom du praticien"
              input-classes="form-control-alternative"
              v-model="searchName"
            >
            </base-input>
          </div>
          <div class="col-lg-1">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a class="dropdown-item" @click="searchResult('recent')"
                  >Récents</a
                >
                <a class="dropdown-item" @click="searchResult('ordre')"
                  >Ordre alphabétique</a
                >
              </template>
            </base-dropdown>
          </div>
        </div>
      </div>

      <base-table
        class="table table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="sortedData"
        style="min-height: 25vh"
        v-if="sortedData && sortedData.length > 0"
      >
        <template slot="columns">
          <th class="text-center">Nom du praticien</th>
          <th class="text-center">Homme/Femme</th>
          <th class="text-center">Téléphone</th>
          <th class="text-center">Email</th>
          <th class="text-center">Pièce juridique</th>
          <th class="text-center">Etat du compte</th>
          <th class="text-center">Etat du praticien</th>
          <th></th>
        </template>

        <template v-slot="{ row }">
          <td class="text-center">{{ row.firstName }} {{ row.lastName }}</td>

          <td class="text-center">
            {{ row.gender }}
          </td>

          <td class="text-center">
            {{ row.phoneNumber }}
          </td>
          <td class="text-center">
            {{ row.email }}
          </td>
          <td class="text-center">
            <!-- <img :src="documentDoctor(row)" alt="" v-if="row.document" style="height:150px;width:100%"/>
            <div v-if="!row.document">
              {{ documentDoctor(row) }}
            </div> -->

            <p v-if="row.document.length == 0" style="font-size: 0.8125rem">
              Pas de document
            </p>
            <div v-if="row.document.length > 0">
              <div v-for="(item, index) in row.document" :key="index">
                <a :href="documentDoctor(item)" target="_blank"
                  >Document justificatif</a
                >
                <br />
              </div>
            </div>
          </td>
          <td class="text-center" v-if="row.disabled">Desactivé</td>
          <td class="text-center" v-if="!row.disabled">Activé</td>
          <td class="text-center" v-if="row.doctorAdminValidation">Vérifié</td>
          <td class="text-center" v-if="!row.doctorAdminValidation">
            Non vérifié
          </td>
          <td class="text-center">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a
                  class="dropdown-item"
                  href="#"
                  @click="(modals.modal = true), getDoctorById(row._id)"
                  >Plus de détails</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click="validateDoctor(row)"
                  v-if="!row.doctorAdminValidation && role != 'viewer'"
                  >Vérifier compte</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click="disableUser(row)"
                  v-if="!row.disabled && role != 'viewer'"
                  >Désactiver compte</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click="enableUser(row)"
                  v-else-if="role != 'viewer'"
                  >Activer compte</a
                >
              </template>
            </base-dropdown>
          </td>
        </template>
      </base-table>
      <div
        class="card-footer d-flex justify-content-end"
        v-if="$props.data.length > 0"
      >
        <ul class="pagination">
          <li class="page-item prev-page" @click="prevPage">
            <a aria-label="Previous" class="page-link">
              <span aria-hidden="true"
                ><i aria-hidden="true" class="fa fa-angle-left"></i></span
            ></a>
          </li>
          <li class="page-item next-page" @click="nextPage">
            <a aria-label="Next" class="page-link"
              ><span aria-hidden="true"
                ><i aria-hidden="true" class="fa fa-angle-right"></i></span
            ></a>
          </li>
          <li></li>
        </ul>
      </div>
      <div class="p-4" v-else>
        <h5>Pas de praticien</h5>
      </div>
    </div>

    <modal :show.sync="modals.modal">
      <h2 slot="header" class="modal-title" id="modal-title-default">
        Fiche Docteur
      </h2>
      <div class="row" style="height: 120px">
        <div class="col-lg-12">
          <div class="card-profile-image">
            <div v-if="!dataForDoctor.photo">
              <img
                v-if="dataForDoctor.gender == 'homme'"
                src="../../assets/profil-homme.png"
                class="rounded-circle"
              />
              <img
                v-else
                src="../../assets/profil-femme.png"
                class="rounded-circle"
              />
            </div>
            <div v-else>
              <img
                style="height: 140px; width: 140px"
                :src="`${$store.state.admin.cloudinaryUrl}${dataForDoctor.photo}`"
                class="rounded-circle"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Nom du praticien"
            placeholder="Nom du praticien"
            input-classes="form-control-alternative"
            disabled
            :value="dataForDoctor.lastName"
          />
        </div>
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Prenom du praticien"
            placeholder="Prénom du praticien"
            input-classes="form-control-alternative"
            disabled
            :value="dataForDoctor.firstName"
          />
        </div>
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Téléphone du praticien"
            placeholder="Téléphonem du praticien"
            input-classes="form-control-alternative"
            disabled
            :value="dataForDoctor.phoneNumber"
          />
        </div>
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Email du praticien"
            placeholder="Email du praticien"
            input-classes="form-control-alternative"
            disabled
            :value="dataForDoctor.email"
          />
        </div>
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Date de naissance du praticien"
            placeholder="Date de naissance du praticien"
            input-classes="form-control-alternative"
            disabled
            :value="getDate(dataForDoctor.dateOfBirth)"
          />
        </div>
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Adresse du praticien"
            placeholder="Adresse du praticien"
            input-classes="form-control-alternative"
            disabled
            :value="dataForDoctor.address"
          />
        </div>
        <div class="col-lg-5">
          <label class="form-control-label"><b>Spécialités</b></label>
          <ul>
            <li
              v-for="(specialite, index) in dataForDoctor.specialty"
              :key="index"
            >
              {{ specialite }}
            </li>
          </ul>
        </div>
        <div class="col-lg-4">
          <label class="form-control-label"><b>Langues parlées</b></label>
          <ul>
            <li
              v-for="(language, index) in dataForDoctor.spokenLanguage"
              :key="index"
            >
              {{ language }}
            </li>
          </ul>
        </div>
        <div class="col-lg-3">
          <label class="form-control-label"><b>Social Media</b></label>
          <!-- <div v-for="(sm, index) in dataForDoctor.socialNetworks" :key="index"> -->
          <!-- <a :href="sm.profileLink" target="_blank">{{ sm.profileLink }}</a> -->
          <ul style="list-style: none">
            <li
              v-for="(element, index) in dataForDoctor.socialNetworks"
              :key="index"
            >
              <a
                :href="element.profileLink"
                target="_blank"
                class="docteur-links"
                v-html="iconSocialNetwork(element.socialNetworkName)"
              ></a>
              <!-- </div> -->
            </li>
          </ul>
        </div>
        <div class="col-lg-12">
          <label class="form-control-label"><b>Education</b></label>
          <ul>
            <li
              v-for="(education, index) in dataForDoctor.education"
              :key="index"
            >
              {{ education }}
            </li>
          </ul>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
export default {
  name: "doctors-table",
  props: {
    data: Array,
    type: {
      type: String,
    },
    title: String,
  },
  computed: {
    role() {
      return this.$store.state.admin.role;
    },
  },
  data() {
    return {
      sortedData: [],
      dataToFilter: [],
      doctorList: [],
      selectedFilters: [],
      pageSize: 5,
      currentPage: 1,
      searchName: "",
      displayType: "tous",
      displayVerif: "tous",
      verif: false,
      modals: {
        modal: false,
      },
      dataForDoctor: [],
    };
  },
  created() {
    setTimeout(() => {
      this.init();
      this.sortedElement();
    }, 1000);
  },
  methods: {
    init() {
      this.dataToFilter = this.$props.data;
      this.doctorList = this.$props.data;
    },
    documentDoctor(row) {
      console.log("test", row);
      return (
        "https://res.cloudinary.com/dba2dcapc/image/upload/v1615605855/" + row
      );
    },
    searchResult(MethodNameFilter) {
      var dataFilterData = this.doctorList;
      if (this.selectedFilters.includes(MethodNameFilter)) {
        this.selectedFilters.splice(
          this.selectedFilters.indexOf(MethodNameFilter),
          1
        );
      }
      if (MethodNameFilter != undefined) {
        this.selectedFilters.push(MethodNameFilter);
      }
      this.selectedFilters.forEach((element) => {
        if (element === "serachName") {
          dataFilterData = this.filterName(dataFilterData);
        }
        if (element === "displayType") {
          dataFilterData = this.filterType(dataFilterData);
        }
        if (element === "displayVerif") {
          dataFilterData = this.filterVerif(dataFilterData);
        }
        if (element === "recent") {
          dataFilterData = this.filterRecent(dataFilterData);
        }
        if (element === "ordre") {
          dataFilterData = this.filterOrder(dataFilterData);
        }
      });
      // dataFilterData.sort((a, b) => b.offer - a.offer);
      this.sortedElement();
    },
    filterRecent: function (dataFilterData) {
      return (this.dataToFilter = dataFilterData.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.createdAt) - new Date(a.createdAt);
      }));
    },
    filterOrder: function (dataFilterData) {
      return (this.dataToFilter = dataFilterData.sort(function (a, b) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        return 0;
      }));
    },
    filterName: function (dataFilterData) {
      var name = "";
      this.dataToFilter = dataFilterData.filter((element) => {
        if (this.searchName === "") {
          return element;
        } else {
          name = element.lastName + " " + element.firstName;
          return this.searchName
            .toLowerCase()
            .split(" ")
            .every((v) => name.toLowerCase().includes(v));
        }
      });
      return this.dataToFilter;
    },
    filterType: function (dataFilterData) {
      var name = "";
      this.dataToFilter = dataFilterData.filter((element) => {
        if (this.displayType === "tous") {
          return element;
        } else if (this.displayType === "desactive") {
          if (element.disabled) {
            return element;
          }
        } else if (this.displayType === "active") {
          if (element.disabled == false) {
            return element;
          }
        }
      });
      return this.dataToFilter;
    },
    filterVerif: function (dataFilterData) {
      var name = "";
      this.dataToFilter = dataFilterData.filter((element) => {
        if (this.displayVerif === "tous") {
          return element;
        } else if (this.displayVerif == "verif") {
          if (element.doctorAdminValidation) {
            return element;
          }
        } else if (this.displayVerif == "nverif") {
          if (!element.doctorAdminValidation) {
            return element;
          }
        }
      });
      return this.dataToFilter;
    },
    sortedElement() {
      this.sortedData = this.dataToFilter.filter((row, index) => {
        let start = (this.currentPage - 1) * this.pageSize;
        let end = this.currentPage * this.pageSize;
        if (index >= start && index < end) return row;
      });
    },
    nextPage: function () {
      if (this.currentPage * this.pageSize < this.$props.data.length) {
        this.currentPage++;
      }
      this.sortedElement();
    },
    prevPage: function () {
      if (this.currentPage > 1) this.currentPage--;
      this.sortedElement();
    },
    iconSocialNetwork(nameSocial) {
      var result = "";
      if (nameSocial == "facebook") {
        result =
          "<i class='fa fa-facebook' style='margin-right:8px;color:#4267B2; border:2px solid #4267B2;padding:12px;border-radius:50%;height:20px;width:20px;display:flex;align-items: center;justify-content: center; '></i> ";
      }
      if (nameSocial == "twitter") {
        result =
          "<i class='fa fa-twitter' style='margin-right:8px;color:#1DA1F2; border:2px solid #1DA1F2;padding:12px;border-radius:50%;height:20px;width:20px;display:flex;align-items: center;justify-content: center; '></i> ";
      }
      if (nameSocial == "linkedIn") {
        result =
          "<i class='fa fa-linkedin' style='margin-right:8px;color:#0e76a8; border:2px solid #0e76a8;padding:12px;border-radius:50%;height:20px;width:20px;display:flex;align-items: center;justify-content: center; '></i> ";
      }
      if (nameSocial == "WhatsApp") {
        result =
          "<i class='fa fa-whatsapp' style='margin-right:8px;color:#075e54; border:2px solid #075e54;padding:12px;border-radius:50%;height:20px;width:20px;display:flex;align-items: center;justify-content: center; '></i> ";
      }
      return result;
    },
    getDate(date) {
      return new Date(date).toLocaleDateString();
    },
    async getDoctorById(id) {
      console.log("getting doctor by id");
      try {
        this.dataForDoctor = await this.$store.dispatch(
          "admin/getDoctorById",
          id
        );
        console.log("doctor", this.dataForDoctor);
      } catch (err) {
        console.log(err);
      }
    },
    async disableUser({ _id }) {
      try {
        let res = await this.$store.dispatch("admin/disableUser", _id);
        console.log(res);
        this.$emit("refresh");
        this.$toast.open({
          message: "Praticien désactivé",
          type: "success",
        });
      } catch (err) {
        console.log(err);
        this.$toast.open({
          message: "Erreur lors de la désactivation",
          type: "error",
        });
      }
    },
    async enableUser({ _id }) {
      try {
        let res = await this.$store.dispatch("admin/enableUser", _id);
        console.log(res);
        this.$emit("refresh");
        this.$toast.open({
          message: "Praticien activé",
          type: "success",
        });
      } catch (err) {
        console.log(err);
        this.$toast.open({
          message: "Erreur lors de l'activation",
          type: "error",
        });
      }
    },
    async validateDoctor({ _id }) {
      try {
        let res = await this.$store.dispatch("admin/validateDoctor", _id);
        console.log(res);
        this.$emit("refresh");
        this.$toast.open({
          message: "Docteur validé",
          type: "success",
        });
      } catch (err) {
        console.log(err);
        this.$toast.open({
          message: "Erreur lors de la validation",
          type: "error",
        });
      }
    },
  },
};
</script>
<style>
.details-fiche {
  padding: 5%;
  background: #82846d;
}
.details-fiche .form-control-label {
  color: #fff !important;
}
#titleCardElem {
  color: #82846d;
  font-size: 0.875rem;
  font-weight: 600;
}
.docteur-links {
  color: #82846d;
}

ul {
  padding-left: 25px;
}
</style>
