<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->
            
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <projects-table-blog title="Blog"></projects-table-blog>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col">
                </div>
            </div>
        </div>

    </div>
</template>
<script>
  import ProjectsTableBlog from './Tables/ProjectsTableBlog'
  export default {
    name: 'tables',
    components: {
      ProjectsTableBlog
    }
  };
</script>
<style>
.bg-gradient-success{
    background: linear-gradient(87deg, #DC0746 0, #55031c 100%) !important;
}
.gradient-success{
    background: linear-gradient(87deg, #DC0746 0, #DC0746 100%) !important;
}
</style>
